@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

/* Carousel Slide  */

.cardwidth {
    width: 500px;
    height: 316px;
    border-radius: 16px;
}

.bestline {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;

}

.smart {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 64px;
    font-weight: 600;
    line-height: 63px;
}

.slick-next {
    display: none;
    right: 0px;
}

@media (min-width:320px) and (max-width:425px) {
    .shop {
        font-size: 20px;
    }
}

@media (min-width:426px) and (max-width: 2560px) {
    .shop {
        color: #666;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
    }

}

@media only screen and (max-width: 768px) {
    .smart {
        color: #FFF;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-weight: 600;
        line-height: 63px;
    }

    .bestline {
        color: #FFF;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;

    }

    .discount {
        color: #FFF;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
    }

    /* .watch {
        width: 150px;
        height: 200px;
    } */

    .circle {
        width: 400px;
        height: 220px;

    }


}
.product__phn{
    border-radius: 16px;
    border: 1px solid #EDEDED;
    background: #F5F5F5;
}
.discount {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
}

.circle {
    width: 500px;
    height: 320px;

}

.slick-arrow slick-next {
    display: none;
}

.slidee {
    background-image: url('../../../public/images/Slider.webp');
    background-position: center/center;
    background-size: 100% 100%;
    height: 320px;
}

.outline {
    width: 132px;
    height: 132px;
    color: #c0b1b1;
}

.view {
    color: #222;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}

.circle img {
    width: 100%;
    height: 100%;
    color: #c0b1b1;
}

.watch {
    width: 268px;
    height: 270px;
}

.watch img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Shop Catgories */

.category {
    color: #1071FF;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.line {
    height: 3px;
    background: #EDEDED;
}

.ellipse {
    width: 150px;
    height: fit-content;
}


.image {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background-color: #F5F5F5;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.watch2 {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background-color: #F5F5F5;
}

.watch2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.plant {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background-color: #F5F5F5;
}

.plant img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.necklace {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    background-color: #F5F5F5;
}

.necklace img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.shoptext {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

/* Grab the Best Deal  */
.grab {
    color: #3A3A3A;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.smart1 {
    color: #1071FF;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.phone {
    width: 84px;
    height: 158px;
    border-radius: 50%;
}

.phone img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.phone2 {
    width: 126px;
    height: 174px;
    border-radius: 50%;
}

.phone2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.phone3 {
    width: 142px;
    height: 188px;
    border-radius: 50%;
}

.phone3 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.phone4 {
    width: 150px;
    height: 188px;
    border-radius: 50%;
}

.phone4 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.phone5 {
    width: 132px;
    height: 212px;
    border-radius: 50%;
}

.phone5 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.phone6 {
    width: 132px;
    height: 212px;
    border-radius: 50%;
}

.phone6 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.label {
    width: 51px;
    height: 53px;
    border-radius: 0px 16px;
    background: #1071FF;
}

.text__offer {
    /* color: #FFFF; */
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #fff;

}

.line2 {
    width: 201px;
    height: 1px;
    background: #EDEDED;
}

.phonewidth {
    width: 227px;
    height: 295px;
    border-radius: 16px;
    background: #F5F5F5;
}

.galaxy {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.save {
    color: #249B3E;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.n1 {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.n2 {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

/* Daily Essentials  */
.daily {
    color: #666;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.essential {
    color: #008ECC;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
}


.backimg {
    width: 187px;
    height: fit-content;
}

.groceries {
    width: 100%;
    height: 187px;
    border-radius: 16px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 3px 7px 0px rgba(0, 0, 0, 0.05), 0px 13px 13px 0px rgba(0, 0, 0, 0.04), 0px 29px 18px 0px rgba(0, 0, 0, 0.03), 0px 52px 21px 0px rgba(0, 0, 0, 0.01), 0px 82px 23px 0px rgba(0, 0, 0, 0.00);
}

.groceries>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 16px;
}
@media (min-width:398px) and (max-width:767px) {
    .backimg{
        row-gap: 10px;
    }
}
.dailytext {
    color: #666;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.dailydiscount {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
}

/* Power Tools  */
.discount__section {
    background-color: #1071FF;
}

.discount__img {
    width: 113px;
    height: 140px;
}

.discount__img img {
    width: 100%;
    height: 100%;
}

.discount__num {
    font-family: 'Poppins', sans-serif;
    color: #FFF;
    font-size: 80px;
    font-weight: 600;
}

.discount__le {
    font-family: 'Poppins', sans-serif;
    color: #FFF;
    font-size: 48px;
    font-weight: 500;
    display: inline-block;
    line-height: normal;
}

.discount__para {
    font-family: 'Poppins', sans-serif;
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

.discount__btn>button {
    width: 171px;
    height: 48px;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    color: #1071FF;
    border-radius: 30px;
}

@media only screen and (max-width:768px) {
    .discount__num {
        font-size: 36px;
    }

    .discount__le {
        font-size: 26px;
    }
}

@media (min-width:1024px) and (max-width:1280px) {
    .discount__num {
        font-size: 42px;
    }

    .discount__le {
        font-size: 26px;
    }
}

@media (min-width:1281px) and (max-width:1536px) {
    .discount__num {
        font-size: 52px;
    }

    .discount__le {
        font-size: 36px;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

/* Manufacture  */
.standard {
  width: 50px;
  height: 50px;
}

.standard img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.company {
  display: flex;
  width: 254px;
  height: 20px;
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
}

.verified {
  
  height: 14px;
}

.verified img {
  width: 41px;
  /* height: 100%; */
}

.gst {
  display: flex;
  width: 254px;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

.contact {
  /* display: flex; */
  width: 108px;
  height: 40px;
  padding: 7.5px 16px 11px 17px;
  /* justify-content: center; */
  align-items: center;
  background: #1071ff;
}

.contacttext {
  /* display: flex; */
  width: 10px;
  height: 17px;
  /* flex-direction: column;
    justify-content: center; */
  color: #fff;
  /* text-align: center; */
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  /* line-height: 40px; */
}
.contact2 {
  /* display: flex; */
  width: 100%;
  height: 40px;
  padding: 7.5px 16px 11px 17px;
  /* justify-content: center; */
  align-items: center;
  background: #1071ff;
}

.contacttext2 {
  /* display: flex; */
  /* width: 10px;
    height: 17px; */
  /* flex-direction: column;
    justify-content: center; */
  color: #fff;
  /* text-align: center; */
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  /* line-height: 40px; */
}

/* Rating and Reviews  */
.rating {
  display: flex;
  width: 154px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.review {
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.count {
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.shirts {
  width: 160px;
  height: 204px;
}

.shirts img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.list {
  width: 320px;
  height: 240px;
}

.list img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.rate {
  display: flex;
  width: 114px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.factory {
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.ullist > li {
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

@media only screen and (min-width: 0px) and (max-width: 375px) {
  .standard {
    width: 45.176px;
    height: 45.648px;
    flex-shrink: 0;
  }
  .company {
    width: 229px;
  }
  .gst {
    display: flex;
    width: 187.027px;
    height: 22.588px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #222;
    font-family: Poppins;
    font-size: 12.649px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.588px; /* 178.571% */
  }
  .shirts {
    width: 97px;
    height: 123px;
  }
  .shirts > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .rate {
    display: flex;
    width: 68.83px;
    height: 14.49px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #222;
    font-family: Inter;
    font-size: 10.868px;
    font-style: normal;
    font-weight: 700;
    line-height: 14.49px; /* 133.333% */
  }
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .rate {
    display: flex;
    width: 68.83px;
    height: 14.49px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #222;
    font-family: Inter;
    font-size: 10.868px;
    font-style: normal;
    font-weight: 700;
    line-height: 14.49px; /* 133.333% */
  }
}
@media  (min-width: 376px) and (max-width: 425px) {
  .list {
    width: 385px;
    height: 225.75px;
    flex-shrink: 0;
  }
  .rate {
    display: flex;
    width: 68.83px;
    height: 14.49px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #222;
    font-family: Inter;
    font-size: 10.868px;
    font-style: normal;
    font-weight: 700;
    line-height: 14.49px; /* 133.333% */
  }
}

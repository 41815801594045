@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');
.productt{
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #fff;
}
.product__titlee{
    color: #1071FF;
    font-size: 26px;
    font-weight: 500;
}
.product__show{
    color: #1071FF;
    font-size: 16px;
    font-weight: 400;
}
.number__per{
    width: 52px;
    height: 32px;
    background: #FF7E27;
    color: #fff;
    position: absolute;
}
.product__star{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #1071FF;
}
.product__imgg{
    width: 100%;
    height: 226px;
}
.product__discount{
    position: relative;
}
.product__imgg img{
    width: 100%;
    height: 100%;
}
.stock > .fa-circle-check{
    color: #00893A;
    font-size: 13px;
    font-weight: 400;
}
.share{
    color: #1071FF;
}
.product__para{
    color: #737B7D;
    font-size: 14px;
    font-weight: 400;
}
.product__title{
    color: #0C0C0C;
    font-size: 20px;
    font-weight: 500;
    height: 101px;
}
.letters{
    color: #1071FF;
    font-size: 16px;
    font-weight: 600;
}
.rupees{
    color: #1071FF;
    font-size: 18px;
    font-weight: 600;
}
.net{
    color: #1071FF;
    font-size: 18px;
    font-weight: 600;
}
.price__dis{
    color: #FF7E27;
    font-size: 16px;
    font-weight: 400;
}
.product__btnn > button{
    width: 100%;
    height: 48px;
    background-color: #1071FF;
    color: #fff;
    border-radius: 30px;
}
@media only screen and (max-width:375px) {
    .product__containerr{
        padding: 0px 20px;
    }
    .product__titlee{
        font-size: 22px;
    }
}
@media (min-width:376px) and (max-width:425px) {
    .product__containerr{
        padding: 0px 40px;
    }
    .product__titlee{
        font-size: 22px;
    }
}
@media (min-width:426px) and (max-width:450px) {
    .product__containerr{
        padding: 0px 40px;
    }
    .product__titlee{
        font-size: 22px;
    }
}
@media (min-width:451px) and (max-width:500px) {
    .product__containerr{
        padding: 0px 50px;
    }
    .product__titlee{
        font-size: 22px;
    }
}
@media (min-width:501px) and (max-width:550px) {
    .product__containerr{
        padding: 0px 70px;
    }
    .product__titlee{
        font-size: 22px;
    }
}
@media (min-width:551px) and (max-width:600px) {
    .product__containerr{
        padding: 0px 100px;
    }
    .product__titlee{
        font-size: 22px;
    }
}
@media (min-width:601px) and (max-width:639px) {
    .product__containerr{
        padding: 0px 120px;
    }
    .product__titlee{
        font-size: 22px;
    }
}
@media (min-width:640px) and (max-width:767px) {
    .product__titlee{
        font-size: 22px;
    }
}
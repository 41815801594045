@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


/* Overview */
.sellertab {
    color: #0C0C0C;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.tab {
    width: auto;

}

.overtab {
    width: 106px;
}

.selleroverview {
    color: #0C0C0C;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 500;
}

.dropcateg {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}

.barchart {
    width: 648px;
}

/* Listings */
.listwidth {
    width: 193px;
    height: 38px;
}

.button {
    width: 91px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.addbutton {
    width: 180px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

/* Listing Tabs  */

.hold {
    width: 132px;
}

.tab1 {
    /* width: 1271px; */

}

.active {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    
}

.card {
    /* width: 1249px; */
}

.lampimg {
    width: 100px;
    height: fit-content;
}

.lampimg img {
    width: 100%;
    object-fit: contain;
}

.tabtext1 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.tabtext2 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.tabrate {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.tabcat {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.addrss {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.symtext {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.size {
    width: 157px;
    height: 36px;
}

.activebox {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    width: 348px;
    height: 38px;
}

.activesearch {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    width: 98px;
    height: 38px;
}

.backbtn {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.addp {
    width: 1271px;
}

.imgupload {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
}

.imgupload2 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.addpbox {
    width: 306px;
    height: 48px;
}

.des {
    /* width: 635px; */
    height: 112px;
}

.imgbox {
    height: 475px;
}

.uploadbtn {
    width: 144px;
    height: 49px;
}

.textupload {
    width: 717px;
    height: 674px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

/* My Account  */
.accounttxt {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.accinputtxt {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 300;
}

#myInput:focus+label,
#myInput:not(:placeholder-shown)+label {
    top: -12PX;
    font-size: 0.75rem;
    background-color: white;
    padding: 0 0.35rem 0 0.35rem;
}

.savebtn {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
}

/* ==============Business address ================ */
.businessaddr {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

input:focus+label,
input:not(:placeholder-shown)+label {
    top: -1PX;
    font-size: 0.75rem;
    background-color: white;
    padding: 0.25rem;
}

.saveBusinessAddr {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
}

.bankDetailsLabel {
    color: #1071FF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.bankTypeLabel {
    color: #2A2A2A;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.currentAccLabel {
    color: #2A2A2A;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.choosefile {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.gstverify {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}
.frequentlyPage__heading{
    color: #0C0C0C;
    font-size: 28px;
    font-weight: 700;
    width: 1300px;
}
.productImg__star{
    width: 32px;
    height: 32px;
    background-color: #E6EFFB;
    border-radius: 50%;
    font-size: 24px;
    color: #1071FF;
}
.frequentlyProduct__content{
    background-color: #F6F8FB;
    border-radius: 8px;
    border: 1px solid #1071FF;
}
.frequentlyProduct__Image{
    width: 238px;
    height: fit-content;
    border-radius: 8px;
    border: 1px solid #F6F8FB;
    background: #FFF;
}
.frequentlyProduct__share{
    width: 100%;
    height: 200px;
    position: relative;
}
.frequentlyProduct__share img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.frequentlyProduct__star{
    position: absolute;
    height: 100%;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;   
}
.frequently__star{
    width: 28px;
    height: 28px;
    background-color: #E6EFFB;
    border-radius: 50%;
    font-size: 20px;
    color: #1071FF;
}
.fre__share{
    color: #1071FF;
}
.brandName{
    color: #737B7D;
    font-size: 14px;
    font-weight: 400;
}
.frequentlyProduct__title{
    color: #0C0C0C;
    font-size: 20px;
    font-weight: 700;
}
.frequentlyProduct__priceTitle{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}
.frequentlyProduct__priceRate{
    color: #1071FF;
    font-size: 20px;
    font-weight: 700;
}
.frequentlyProduct__stricke{
    color: #FF7E27;
    font-size: 16px;
    font-weight: 500;
}
.frequentlyProduct__addBtn{
    width: 209px;
    height: 40px;
    border-radius: 30px;
    background-color: #1071FF;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
}
@media only screen and (max-width:374px) {
    .fre{
        flex-wrap: wrap;
        gap: 20px;
    }
}
@media (min-width:375px) and (max-width:425px) {
    .frequentlyProduct__Image{
        width: 160px;
    }
    .fre{
        gap: 5px;
    }
    .frequentlyProduct__share{
        height: 160px;
    }
    .frequentlyProduct__share{
        height: 100%;
    }
    .frequentlyProduct__title{
        color: #0C0C0C;
        font-size: 16px;
        font-weight: 700;
    }
}


/********* Contact style *******/

.contact__container{
    width: 515px;
    height: fit-content;
    background-color: #F6F8FB;
    border-radius: 8px;
    border: 1px solid #629CFF;
}
.contact__title{
    color: #1071FF;
    font-size: 24px;
    font-weight: 700;
}
.contact__detail{
    height: 96px;
    width: 100%;
}
.contact__user{
    width: 96px;
    height: 100%;
    color: #1071FF;
    font-size: 32px;
}
.contact__name{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 700;
}
.contact__profession{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.contact__product{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.schedule__meeting{
    width: 100%;
    height: 48px;
    border-radius: 30px;
    background-color: #1071FF;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}
.contact__call, .contact__chat{
    width: 100%;
    height: 48px;
    border-radius: 30px;
    background-color: #fff;
    color: #1071FF;
    font-size: 16px;
    font-weight: 400;
}
.video__icon{
    font-size: 22px;
}
@media only screen and (max-width: 520px) {
    .contact__container{
        width: 100%;
    }
    .contact__user{
        width: 66px;
        height: 66px;
    }
    .contact__title{
        font-size: 18px;
    }
    .contact__name{
        font-size: 14px;
    }
    .contact__profession{
        font-size: 14px;
    }
}

.splide{
    margin-top: 140px;
}
.slide{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 0px 30px 0px 0px;
    width: 745px;
    height: 262px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
}
.slider__content{
    width: 400px;
}
.slide__image{
    width: 245px;
    height: 100%;
}
.slider__date{
    color: #525252;
    font-size: 12px;
    font-weight: 400;
}
.slider__title{
    color: #525252;
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0px 20px;
}
.slider__para{
    color: #525252;
    font-size: 12px;
    font-weight: 400;
}
.slide__image img{
    width: 100%;
    height: 100%;
}
.slick-next, .slick-prev{
    display: none !important;
}
.user__img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #e9e7e7;
}
.user__silder{
     font-size: 56px;   
}
.slick-dots {
    bottom: -60px !important;
}
@media only screen and (max-width:360px) {
    .slide{
        width: 300px;
        height: 210px;
        padding: 0px 10px 0px 0px;
    }
    .slider__title{
        margin: 0px;
    }
    .slider__title{
        font-size: 14px;
    }
    .slider__para{
        font-size: 10px;
    }
    .user__img{
        width: 80px;
        height: 80px;
    }
    .user__silder{
        font-size: 32px;
    }
}
@media (min-width:361px) and (max-width:425px) {
    .slide{
        width: 350px;
        height: 210px;
        padding: 0px 10px 0px 0px;
    }
    .slider__title{
        margin: 0px;
    }
    .slider__title{
        font-size: 14px;
    }
    .slider__para{
        font-size: 10px;
    }
    .user__img{
        width: 80px;
        height: 80px;
    }
    .user__silder{
        font-size: 32px;
    }

}
@media (min-width:426px) and (max-width: 500px){
    .slide{
        width: 380px;
        height: 210px;
        padding: 0px 10px 0px 0px;
    }
    .slider__title{
        margin: 0px;
    }
    .slider__title{
        font-size: 14px;
    }
    .slider__para{
        font-size: 10px;
    }
    .slider__content{
        width: 320px;
        margin-left: 10px;
    }
    .user__img{
        width: 80px;
        height: 80px;
    }
    .user__silder{
        font-size: 32px;
    }

}
@media (min-width:501px) and (max-width: 580px){
    .slide{
        width: 400px;
        height: 210px;
        padding: 0px 10px 0px 0px;
    }
    .slider__title{
        margin: 0px;
    }
    .slider__title{
        font-size: 14px;
    }
    .slider__para{
        font-size: 10px;
    }
    .slider__content{
        width: 320px;
        margin-left: 10px;
    }
}
@media (min-width:581px) and (max-width: 640px){
    .slide{
        width: 500px;
        height: 210px;
        padding: 0px 10px 0px 0px;
    }
    .slider__title{
        font-size: 14px;
    }
    .slider__para{
        font-size: 10px;
    }
    .slider__content{
        width: 320px;
        margin-left: 10px;
    }
}
@media (min-width: 641px) and (max-width: 768px){
    .slide{
        width: 500px;
        height: 210px;
        padding: 0px 10px 0px 0px;
    }
    .slider__content{
        width: 320px;
        margin-left: 10px;
    }

}
@media (min-width: 769px) and (max-width: 1100px){
    .slide{
        width: 380px;
        height: 210px;
        padding: 0px 10px 0px 0px;
    }
    .slider__content{
        width: 320px;
        margin-left: 10px;
    }

}
@media (min-width: 1101px) and (max-width:1280px){
    .slide{
        width: 500px;
        height: 250px;
        padding: 0px 10px 0px 0px;
    }
    .slider__content{
        width: 320px;
        margin-left: 10px;
    }
}
@media (min-width: 1281px) and (max-width: 1550px){
    .slide{
        width: 600px;
        height: 250px;
        padding: 0px 10px 0px 0px;
    }
    .slider__content{
        width: 320px;
        margin-left: 10px;
    }
}


.success__contentTitle{
    color: #020246;
    font-size: 26px;
    font-weight: 700;
}
.success__contentPara{
    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;
    font-weight: 400;
}
.success__detailImg{
    width: 120px;
    height: 120px;
}
.success__detailImg > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.success__msg{
    color: #1071FF;
    font-size: 18px;
    font-weight: 500;
}
.success__para{
    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;
    font-weight: 400;
}
.tokenId{
    color: #020246;
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;
}
.order__review{
    border-radius: 6px;
    border: 0.383px solid #B2BCCA;
    background: #FFF;
}
.success__btn > button{
    width: 100%;
    height: 37px;
    background-color: #1071FF;
    border-radius: 6px;
    box-shadow: 6.2px 6.2px 18.6px 0px rgba(2, 2, 70, 0.15);
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
}
.success__detail{
    border-radius: 24px;
    background-color: #FFF;
    box-shadow: 12px 12px 36px 6px rgba(2, 2, 70, 0.12);
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');


.footer{
    background-color: #F6F8FB;
}
.footer__container{
    border-bottom: 1px solid #101828;
}
.sign{
    width: 300px;
}
.manufacturing{
    width: 210px;
}
.sign__title{
    color: #0C0C0C;
    font-size: 20px;
    font-weight: 700;
}
.sign__para{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}
.sign__input > input{
    width: 100%;
    height: 48px;
    border: 1px solid #EAECEE;
    border-radius: 12px;
    padding: 0px 16px;
    outline: none;
}
.footer__btn > button{
    width: 100%;
    height: 48px;
    background-color: #2580FF;
    color: #fff;
    border-radius: 30px;
}
.footer__accept{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}
.blue{
    color: #2580FF;
    font-size: 14px;
    font-weight: 400;
}
.manufacturing__title, .retail__title, .finance__title{
    color: #000;
    font-size: 14px;
    font-weight: 600;
}
.manufacturing > ul > li > a,
.retail > ul > li > a,
.finance > ul > li > a {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}
.footer__rights{
    height: fit-content;
}
.footer__mart{
    color: #434447;
    font-size: 14px;
    font-weight: 400;
}
.footer__social{
    font-size: 24px;
}
@media only screen and (max-width:425px) {
    .footer__social{
        font-size: 16px;
    }
    .manufacturing{
        width: 100%;
    }
}
@media (min-width:426px) and (max-width:500px) {
    .footer__icon{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .footer__social{
        margin-right: 50px;
    }
    .manufacturing{
        width: 100%;
    }

}
@media (min-width:501px) and (max-width:639px){
    .footer__rights, .footer__icon{
        display: flex;
        justify-content: center;
    }
    .footer__social, .footer__mart{
        margin-right: 40px;
    }
    .manufacturing{
        width: 100%;
    }

}
@media (min-width:640px) and (max-width:769px){
    .footer__social{
        font-size: 18px;
    }
}
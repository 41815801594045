

/* Filter Options  */
.hidden1 {
    display: none !important;
}

/* .block {
    display: block !important;
} */
.block1{
    display: block !important;
}
.filtersbtn {
    font-family: 'Poppins', sans-serif;
    color: #1071FF;
    font-size: 14px;
    font-weight: 500;
}

.subbg {
    /* display: inline-flex; */
    /* width: 301px; */
    /* height: 311px; */
    padding: 40px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    background: #F6F8FB;
}

.title {
    color: #0C0C0C;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
}

.back {
    color: #0C0C0C;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 170%;
}

.category1 {
    color: #1071FF;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 170%;
}

.checkbox {
    width: 16px;
    height: 16px;
    background-color: #fff;
    color: #1071FF;
}

.text {
    color: #0C0C0C;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.categorynumber {
    color: #737B7D;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.filtersbg {
    display: inline-flex;
    padding: 40px 24px;
    align-items: flex-start;
    border-radius: 8px;
    background: #F6F8FB;
}

.filtertext {
    color: #0C0C0C;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.clearall {
    color: #1071FF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.filtersearch {
    font-family: 'Poppins', sans-serif;
    color: #737B7D;
    font-size: 14px;
    font-weight: 400;
}



.filterdropdown {
    font-family: 'Poppins', sans-serif;
    color: #434447;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    width: 211px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
}

.filtergroup {
    font-family: 'Poppins', sans-serif;
    color: #434447;
    font-size: 16px;
    font-weight: 700;
}

.filloption {
    font-family: 'Poppins', sans-serif;
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}

.filternumber {
    font-family: 'Poppins', sans-serif;
    color: #737B7D;
    font-size: 16px;
    font-weight: 400;
}

.showmore {
    font-family: 'Poppins', sans-serif;
    color: #1071FF;
    font-size: 16px;
    font-weight: 400;
}

/* Products you may like  */

.dropArea {
    width: 250px;
    height: 250px;
    background: rgba(217, 217, 217, 0.40);
}

.product__image {
    width: 250px;
    height: 250px;
    object-fit: contain;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product {
    width: 100%;
    height: fit-content;
    background: #EFEFEF;
}

.product__container {
    display: flex;
    justify-content: end;
    column-gap: 100px;
}

.product__content {
    width: 920px;
    height: 611px;
    background-color: #fff;
}

.product__img {
    width: 370px;
    height: 589px;
    background-color: #fff;
    margin-right: 30px;
    margin-top: 20px;
}

.product__title {
    color: #1071FF;
    font-size: 22px;
    font-weight: 500;
}

.product__para {
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}

.product__input {
    outline: none;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    padding: 0px 12px;
    height: 48px;
    color: #898989;
    font-size: 14px;
    font-weight: 400;
    display: grid;
}

.product__input>input {
    outline: none;
}

.product__btn {
    width: 374px;
    height: 42px;
    background-color: #1071FF;
    color: #fff;
    outline: none;
}
.product__btn > button{
    width: 100%;
    height: 100%;
}
.dropArea__title {
    color: #1071FF;
    font-size: 14px;
    font-weight: 500;
}

.dropArea__para {
    color: #0C0C0C;
    font-size: 12px;
    font-weight: 500;
}

.fa-upload {
    font-size: 32px;
}

.t-shirt__img {
    width: 230px;
    height: 230px;
}

.t-shirt__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fa-circle-xmark {
    font-size: 32px;
    color: red;
}

.fa-circle-check {
    font-size: 32px;
    color: rgb(31, 230, 31);
}

@media (min-width:1831px) and (max-width: 1859px) {
    .product__container {
        column-gap: 80px;
    }
}

@media (min-width: 1800px) and (max-width:1830px) {
    .product__container {
        column-gap: 70px;
    }
}

@media (min-width: 1760px) and (max-width:1799px) {
    .product__container {
        column-gap: 50px;
    }
}

@media (min-width: 1730px) and (max-width:1759px) {
    .product__container {
        column-gap: 25px;
    }
}

@media (min-width: 1680px) and (max-width:1729px) {
    .product__container {
        column-gap: 10px;
    }
}

@media (min-width: 1630px) and (max-width:1679px) {
    .product__container {
        column-gap: 10px;
    }

    .product__img {
        margin-right: 0px;
    }
}

@media (min-width: 1580px) and (max-width:1629px) {
    .product__container {
        column-gap: 10px;
    }

    .product__img {
        margin-right: 0px;
        width: 350px;
    }
}

@media (min-width: 1534px) and (max-width:1579px) {
    .product__container {
        column-gap: 10px;
    }

    .product__img {
        margin-right: 0px;
        width: 330px;
    }
}

@media only screen and (max-width: 1534px) {
    .product__container {
        display: grid;
        place-content: center;
    }

    .product {
        height: fit-content;
    }

    .product__img {
        width: 920px;
        height: fit-content;
        display: flex;
        align-items: center;
        column-gap: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .product__content {
        width: 100%;
        height: fit-content;
    }

    .product__img {
        display: none;
    }

    .product__btn {
        width: 300px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .product__img {
        width: 100%;
    }

    .t-shirt__img {
        width: 250px;
    }
}

/* ProductMain  */
.productt {
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #fff;
}

.product__titlee {
    font-family: 'Poppins', sans-serif;
    color: #1071FF;
    font-size: 26px;
    font-weight: 500;
}

.product__show {
    font-family: 'Poppins', sans-serif;
    color: #1071FF;
    font-size: 16px;
    font-weight: 400;
}

.number__per {
    width: 52px;
    font-family: 'Poppins', sans-serif;
    height: 32px;
    background: #FF7E27;
    color: #fff;
}

.product__star {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #1071FF;
    
}

.product__imgg {
    width: 100%;
    height: 226px;
}

.product__imgg1 {
    width: 300px;
    height: 240px;
}
.product__imgg1 img{
    width: 100%;
    height: 100%;
}
.product__discount{
    position: relative;
}   
.product__imgg img {
    width: 100%;
    height: 100%;
}
.categoryimg{
    width: 100%;
    height: 50px;
    position: absolute ;
    top: 0;
}

.stock>.fa-circle-check {
    color: #00893A;
    font-size: 13px;
    font-weight: 400;
}

.stock>span {
    font-family: 'Poppins', sans-serif;
    color: #00893A;
    font-size: 13px;
    font-weight: 400;
}

.share {
    color: #1071FF;
}

.cart1 {
    border-radius: 8px;
    border: 1px solid #EAECEE;
    background: #FFF;
    display: flex;
    /* width: 92px; */
    height: 48px;
    padding: 5px 24px 6px 24px;
    justify-content: center;
    align-items: center;
}

.cartbox {
    display: flex;
    /* width: 73px; */
    height: 48px;
    padding: 8px 16px 9px 17px;

    border-radius: 8px;
    border: 1px solid #EAECEE;
    background: #FFF;
}

.cart2 {
    border-radius: 8px;
    display: flex;
    background: #1071FF;
    padding: 5px 24px 6px 24px;
    justify-content: center;
    align-items: center;
}

.product__para {
    font-family: 'Poppins', sans-serif;
    color: #737B7D;
    font-size: 14px;
    font-weight: 400;
}

.product__title {
    font-family: 'Poppins', sans-serif;
    color: #0C0C0C;
    font-size: 20px;
    font-weight: 500;
}

.letters {
    font-family: 'Poppins', sans-serif;
    color: #1071FF;
    font-size: 16px;
    font-weight: 600;
}

.rupees {
    font-family: 'Poppins', sans-serif;
    color: #1071FF;
    font-size: 18px;
    font-weight: 600;
}

.net {
    font-family: 'Poppins', sans-serif;
    color: #1071FF;
    font-size: 18px;
    font-weight: 600;
}

.price__dis {
    font-family: 'Poppins', sans-serif;
    color: #FF7E27;
    font-size: 16px;
    font-weight: 400;
}

.product__btnn>button {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 48px;
    background-color: #1071FF;
    color: #fff;
    border-radius: 30px;
}

.product__btnn1>button {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 48px;
    background: rgba(199, 199, 199, 0.50);
    color: black;
    border-radius: 30px;
}

@media only screen and (max-width:375px) {
    .product__containerr {
        padding: 0px 20px;
    }

    .product__titlee {
        font-size: 22px;
    }
}

@media (min-width:376px) and (max-width:425px) {
    .product__containerr {
        padding: 0px 40px;
    }

    .product__titlee {
        font-size: 22px;
    }
}

@media (min-width:426px) and (max-width:450px) {
    .product__containerr {
        padding: 0px 40px;
    }

    .product__titlee {
        font-size: 22px;
    }
}

@media (min-width:451px) and (max-width:500px) {
    .product__containerr {
        padding: 0px 50px;
    }

    .product__titlee {
        font-size: 22px;
    }
}

@media (min-width:501px) and (max-width:550px) {
    .product__containerr {
        padding: 0px 70px;
    }

    .product__titlee {
        font-size: 22px;
    }
}

@media (min-width:551px) and (max-width:600px) {
    .product__containerr {
        padding: 0px 100px;
    }

    .product__titlee {
        font-size: 22px;
    }
}

@media (min-width:601px) and (max-width:639px) {
    .product__containerr {
        padding: 0px 120px;
    }

    .product__titlee {
        font-size: 22px;
    }
}

@media (min-width:640px) and (max-width:767px) {
    .product__titlee {
        font-size: 22px;
    }
}

.pagenumbers {
    color: #0C0C0C;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.numberbox {
    border-radius: 8px;
    background: #1071FF;
}

.pagefrom {
    color: #737B7D;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}


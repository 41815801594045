@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');


.header__section{
    height: 76px;
    background-color: #F6F8FB;
    
}
.martLogo{
    width: 165px;
    height: 34px;
}
.martLogo  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.headerV__searchContainer{
    width: 634px;
    height: 48px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    display: flex;
    align-items: center;
    padding: 0px 12px;
}
.headerV__input{
    width: 100%;
}
.headerV__input > input{
    width: 100%;
    outline: none;
}
.search__icon{
    color: #1071FF;
    font-size: 24px;
}
.ico{
    color: #1071FF;
    font-size: 18px;
}
.icon{
    color: #1071FF;
    font-size: 20px;
}
.fauser{
    color: #1071FF;
    font-size: 16px;
}
.shopIcon{
    font-size: 22px;
}
.header__detail > span{
    color: #2A2A2A;
    font-size: 14px;
    font-weight: 500;
}
.envelope{
    position: relative;
}
.message, .shopping, .help {
    font-size: 14px;
    font-weight: 500;
    color: #2A2A2A;
}
.header__btn{
    font-size: 14px;
    font-weight: 500;
    color: #1071FF;
}
.number{
    width: 15px;
    height: 15px;   
    border-radius: 50%;
    background-color: red;
    color: #fff;
    position: absolute;
    top: -4px;
    right: -6px;
    display: grid;
    place-content: center;
    font-size: 10px;
}
.shopping, .message{
    width: 110px;
    border-right: 1px solid #6e7072;
}
.sell, .help{
    width: 70px;
    border-right: 1px solid #6e7072;
}

.mobile__menu{
    position: absolute;
    top: 57px;
    right: 0;
    padding: 30px;
    background-color: #1071FF;
    color: #fff;
    width: 100%;
    z-index: 2;
    display: none;
}
.header__btnMob{
    width: 100%;
    height: 50px;
}
@media only screen and (max-width:320px) {
    .header__section{
        height: 56px;
    }
    .martLogo{
        width: 125px;
        height: 27px;
    }
    .number{
        right: 6px;
    }
    .header__btn{
        color: #1071FF;
        margin-top: 30px;
    }
    .icons{
        color: #fff;
    }
    .nav__link:hover{
        border-bottom: 1px solid #fff;
    }
    .numbers{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: red;
    color: #fff;
    position: absolute;
    top: -4px;
    right: -6px;
    display: grid;
    place-content: center;
    font-size: 10px;
    }
}
@media (min-width:321px) and (max-width:375px) {
    .header__section{
        height: 56px;
    }
    .martLogo{
        width: 125px;
        height: 27px;
    }
    .number{
        right: 6px;
    }
    .header__btn{
        color: #1071FF;
        margin-top: 30px;
    }

    .icons{
        color: #fff;
    }
    .nav__link:hover{
        border-bottom: 1px solid #fff;
    }
    .numbers{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: red;
    color: #fff;
    position: absolute;
    top: -4px;
    right: -6px;
    display: grid;
    place-content: center;
    font-size: 10px;
    }
}
@media (min-width:376px) and (max-width:425px) {
    .header__section{
        height: 56px;
    }
    .martLogo{
        width: 125px;
        height: 27px;
    }
    .number{
        right: 6px;
    }
    .icons{
        color: #fff;
    }
    .nav__link:hover{
        border-bottom: 1px solid #fff;
    }
    .header__btn{
        color: #1071FF;
        margin-top: 30px;
    }

    .numbers{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: red;
    color: #fff;
    position: absolute;
    top: -4px;
    right: -6px;
    display: grid;
    place-content: center;
    font-size: 10px;
    }
}
@media (min-width:426px) and (max-width:640px) {
    .header__section{
        height: 60px;
    }
    .martLogo{
        width: 125px;
        height: 27px;
    }
    .number{
        right: 6px;
    }
    .icons{
        color: #fff;
    }
    .nav__link:hover{
        border-bottom: 1px solid #fff;
    }
    .header__btn{
        color: #1071FF;
        margin-top: 30px;
    }

    .numbers{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: red;
    color: #fff;
    position: absolute;
    top: -4px;
    right: -6px;
    display: grid;
    place-content: center;
    font-size: 10px;
    }
}
@media (min-width:641px) and (max-width:768px) {
    .numbers{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: red;
        color: #fff;
        position: absolute;
        top: -4px;
        right: -6px;
        display: grid;
        place-content: center;
        font-size: 10px;
    }
}
@media (min-width: 769px) and (max-width: 1023px) {
    .numbers{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: red;
        color: #fff;
        position: absolute;
        top: -4px;
        right: -6px;
        display: grid;
        place-content: center;
        font-size: 10px;
    }
    .shopping, .message{
        width: 102px;
    }
}
/* Nav Style */

.nav__section{
    height: 82px;
}
nav > ul > li > a{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}
.search__box{
    width: 543px;
    height: 48px;
    border: 1px solid #EAECEE;
    padding: 0px 16px;
    border-radius: 8px;
}
.search__box input{
    background-color: transparent;
    outline: none;
    width: 100%;
}
.fa-magnifying-glass{
    font-size: 12px;
    color: #1071FF;
}
.search__box > input::placeholder{
    color:#737B7D;
    font-size: 14px;
    font-weight: 400;
}
@media only screen and (max-width:321px) {
    .search__box{
        width: 100%;
        height: 38px;
    }
}
@media (min-width:321px) and (max-width:375px) {
    .search__box{
        width: 100%;
        height: 38px;
    }
}
@media (min-width:376px) and (max-width:425px) {
    .search__box{
        width: 100%;
        height: 38px;
    }
}
@media (min-width:426px) and (max-width:640px) {
    .search__box{
        width: 100%;
        height: 43px;
    }
}
@media (min-width:641px) and (max-width:768px) {
    .search__box{
        width: 100%;
        height: 43px;
    }
}
@media (min-width:769px) and (max-width:1023px) {
    .nav__section{
        display: flex;
       justify-content: center;

    }    
}
@media (min-width:1024px) and (max-width:1280px) {
    .search__box{
        width: 300px;
    }
}

/********* Mobile Nav style ********/

.mobileNav__container{
   width: 375px;
   background-color: #ffffff;
   display: none;
   padding: 0px 20px;
   position: absolute;
   top: 0px;
   left: 0px;
   z-index: 1;
}
.mobileNav__container.mobileNav__containerOpen{
    display: block;
    /* left: 100%;
    transition: all 10s ease-in-out; */
}
.mobileNav__closeIcon > button{
    font-size: 24px;
    font-weight: 600;
}
.home__nav{
    width: 100%;
    height: 46px;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
}
.clothing{
    width: 100%;
    height: 56px;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
}
.mobileNav__closeIcon,  .clothing, .home__nav{
    border-bottom: 1px solid #EAECEE;
    height: 57px;
}

.mobile__ul > li{
    font-size: 14px;
    font-weight: 400;
    padding: 10px 0px;
}
.mabileNav__msg{
    width: 100%;
    height: 56px;
    padding: 16px;
    color: #1071FF;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #EAECEE;
}

@media only screen and (max-width:375px) {
    .mobileNav__container{
        width: 100%;
    }
}

.payment__title{
    color: #1071FF;
    font-size: 14px;
    font-weight: 500;
}
.creditCard__container{
    overflow: hidden;
    border-radius: 6px;
    border: 0.778px solid #1071FF;
    background-color: #EFF0F7;
}
.creditCard__title{
    color: #344054;
    font-size: 11px;
    font-weight: 500;
}
.visa, .visa__icon{
    width: 35px;
    height: 24px;
}
.visa > img, .visa__icon > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.creditCard__inputName > label, .creditCard__expiry > label{
    display: block;
    color: #344054;
    font-size: 14px;
    font-weight: 500;
}
.creditCard__inputName > input{
    width: 360px;
    height: 34px;
    border-radius: 6px;
    border: 0.778px solid #D0D5DD;
    background-color: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); 
    outline: none;  
    padding: 0px 12px;
}
.creditCard__expiry > input{
    width: 87px;
    height: 34px;
    border-radius: 6px;
    border: 0.778px solid #D0D5DD;
    background-color: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);   
    outline: none;
    padding: 0px 12px;
}
@media only screen and (max-width:320px) {
    .creditCard__inputName > input{
        width: 80px;
    }
}
@media (min-width:321px) and (max-width:360px) {
    .creditCard__inputName > input{
        width: 120px;
    }
}
@media (min-width:361px) and (max-width:375px) {
    .creditCard__inputName > input{
        width: 150px;
    }
}
@media (min-width:376px) and (max-width:425px) {
    .creditCard__inputName > input{
        width: 180px;
    }
}
@media (min-width:426px) and (max-width:500px) {
    .creditCard__inputName > input{
        width: 220px;
    }
}
@media (min-width:501px) and (max-width: 570px) {
    .creditCard__inputName > input{
        width: 300px;
    }
}

/****** upi style ******/


.upi__container{
    overflow: hidden;
    border-radius: 6px;
    border: 0.778px solid #1071FF;
    background-color: #EFF0F7;
}
.upi__title{
    color:#344054;
    font-size: 10px;
    font-weight: 500;
}
.upi__image{
    width: 35px;
    height: 25px;
}
.upi__image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.upi__gpay, .upi__phnpay, .upi__paytm{
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.upi__gpay > img, .upi__phnpay > img .upi__paytm > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}


/******* cash delivery style *******/

.cashDelivery__container{
    border-radius: 6px;
    border: 0.778px solid #1071FF;
    background-color: #EFF0F7;
}
.cashDelivery__title{
    color:#344054;
    font-size: 10px;
    font-weight: 500;
}
.cashDelivery__image{
    width: 35px;
    height: 25px;
}
.cashDelivery__image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}





/************** CheckBox creadit style ************/

.creadit__check{
    width: 100%;
    height: 65px;
    border-radius: 6px;
    background-color: #EFF0F7;
}
.checkbox__img{
    width: 35px;
    height: 25px;
    object-fit: cover;
}
.checkbox__name{
    color: #344054;
    font-size: 11px;
    font-weight: 500;
}
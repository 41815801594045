@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');
.tab__container{
    width: 100%;
    background-color: #fff;
}
.tab__navigation{
    width: 920px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.tabBtn{
    width: 170px;
}

@media only screen and (max-width:500px) {
    .tab__navigation{
        width: 100%;
        justify-content: center;
        gap: 20px;
    }
    .tabBtn{
        width: 150px;
        font-size: 12px;
    }
}
@media (min-width:501px) and (max-width:920px) {
    .tab__container{
        width: 100%;
    }
    .tab__navigation{
        width: 100%;
        justify-content: center;
        gap: 20px;
    }
    .create__account{
        display: block;
    }
    .tabBtn{
        width: 150px;
        font-size: 13px;
    }
}
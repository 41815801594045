@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');
.main__section{
    background-color: #1071FF;
    color: #fff;
}
.main__title{
    color: #FFF;
    font-size: 60px;
    font-weight: 600;
    line-height: 87px;
}
.main__para{
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}
.main__img{
    height: 339px;
}
.main__img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
@media only screen and (max-width:320px) {
    .main__title{
        font-size: 28px;
        line-height: 40px;
    }
}
@media (min-width:321px) and (max-width:375px) {
    .main__title{
        font-size: 28px;
        line-height: 40px;
    }
}
@media (min-width:376px) and (max-width:425px) {
    .main__title{
        font-size: 28px;
        line-height: 40px;
    }
}
@media (min-width:426px) and (max-width:640px) {
    .main__container{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .main__title{
        font-size: 28px;
        line-height: 40px;
    }
}
@media (min-width:641px) and (max-width:767px) {
    .main__container{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .main__title{
        font-size: 28px;
        line-height: 40px;
    }
}
@media (min-width:768px) and (max-width:1023px) {
    .main__image{
        display: grid;
        place-content: center;
    }
    .main__img{
        width: 411px;
    }
    .main__container{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .main__title{
        font-size: 38px;
        line-height: 60px;
    }
}
@media (min-width:1024px) and (max-width:1279px) {
    .main__title{
        font-size: 42px;
        line-height: 60px;
    }
    .main__content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .main__img{
        height: 300px;
    }
}
@media (min-width:1280px) and (max-width:1536px) {
    .main__title{
        font-size: 42px;
        line-height: 60px;
    }
    .main__content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0px 20px 0px 0px ;
    }
    .main__img{
        height: 300px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.welcome {
    color: #0C1421;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.36px;
}

.description { 
    color: #313957;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.16px;
}

/* Background Image  */

.background__login {
    width: 983px;
    height: fit-content;
}

.background__login > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


/* Form  */
.email {
    color: #0C1421;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
}

.emailbox {
    width: 388px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #D4D7E3;
    background: #F7FBFF;
}

@media only screen and (max-width: 40px) {
    .emailbox, .passwordbox{
        width: 100%;
    }
}
.emailplaceholder {
    color: #8897AD;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.14px;
}

.password {
    color: #0C1421;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
}

.passwordbox {
    width: 388px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #D4D7E3;
    background: #F7FBFF;
}

.passwordplaceholder {
    color: #8897AD;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.14px;
}

/* Forgot Password  */

.forgot {
    color: #1E4AE9;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
}

/* Sigin In  */
.signinbox {
    width: 100%;
    padding: 16px 0px;
    border-radius: 12px;
    background: #1071FF;
    height: 48px;
} 

.signintext > button {
    width: 100%;
    color: #FFF;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.16px;
    height: 100%;
}

/* Signup  */
.signupbox {
    color: #313957;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.16px;
}
.MuiButton-root {
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.signup {
    color: #1E4AE9;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.16px;
}

/* CopyRight  */
.copyrights {
    color: #959CB6;
    /* text-align: center; */
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.16px;
}

@media only screen and (max-width:320px) {
    .emailbox{
       padding-right: 20px;
    }
}
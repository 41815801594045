.order__review, .check__summary{
    border-radius: 6px;
    border: 0.383px solid #B2BCCA;
    background: #FFF;
}
.check__summaryContent{
    border-bottom: 1px solid #B2BCCA;
}
.order__itemsRupees{
    color: #667085;
    font-size: 12px;
    font-weight: 400;
}
.order__btn > button{
    width: 100%;
    height: 37px;
    background-color: #1071FF;
    border-radius: 6px;
    box-shadow: 6.2px 6.2px 18.6px 0px rgba(2, 2, 70, 0.15);
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
}
.emptyCategory__emoji{
    width: 411px;
    height: fit-content;
}
.emoji__Img{
    width: 250px;
    height: 250px;
}
.emoji__Img > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.emptyCategory__content{
    color: #3A3A3A;
    font-size: 24px;
    font-weight: 600;
}
@media only screen and (max-width:411px) {
    .emptyCategory__emoji{
        width: 100%;
    }
    .emoji__Img{
        width: 150px;
        height: 150px;
    }
    .emptyCategory__content{
        font-size: 18px;
    }
}
@media (min-width:412px) and (max-width:640px) {
    .emoji__Img{
        width: 150px;
        height: 150px;
    }
    .emptyCategory__content{
        font-size: 18px;
    }
}
@media (min-width:641px) and (max-width:768) {
    .emoji__Img{
        width: 200px;
        height: 200px;
    }
    .emptyCategory__content{
        font-size: 22px;
    }
}
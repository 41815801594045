.description__Tab{
    width: 118px;
    height: 48px;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-weight: 400;
}
.technical__Tab{
    width: 159px;
    height: 48px;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-weight: 400;
}
.review__Tab{
    width: 118px;
    height: 48px;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-weight: 400;
}
.aboutProduct__Tab{
    width: 170px;
    height: 48px;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-weight: 400;
}
.productTab__navigation, .productTab__content{
    width: 1300px;
    height: fit-content;
}
.productTab__content{
    background-color: #E6EFFB;
}
@media only screen and (max-width:1350px) {
    .productTab__navigation, .productTab__content{
        width: 100%;
    }
}


/******** Description style *******/


.description__container{
    background-color: #fff;
    border-radius: 8px;
}
.right__title{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 700;
}
.right__content{
    width: 300px;
    height: 253px;
    background-color: #F6F8FB;
}
.description__list > li{
    margin: 7px 0px;
}


/********* TechnicalTab style ********/

.technical__contanier{
    background-color: #fff;
    border-radius: 8px;
}
.parameter1{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}
.parameter2{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 600;
}
.parameter__rowColor{
    width: 100%;
    height: 40px;
    background-color: #F6F8FB;
    margin: 10px 0px;
}


/********** ReviewTab style **********/

.reviewTab__title{
    color: #0C0C0C;
    font-size: 24px;
    font-weight: 700;
}
.reviewTab__Rating{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.reviewTab__IosStar{
    color: #FF924A;
    font-size: 16px;
}
.reviewTab__detail{
    height: 650px;
    overflow-y: auto;
}
.reviewTab__reviews{
    width: 554px;
    height: 247px;
    background-color: #fff;
    border: 1.5px solid #EAECEE;
    border-radius: 8px;
}
.reviews__date{
    color: #737B7D;
    font-size: 14px;
    font-weight: 400;
}
.reviews__para{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.reviews__addComment{
    color: #1071FF;
    font-size: 16px;
    font-weight: 400;
}
.like{
    color: #1071FF;
    font-size: 24px;
}
.technicalTab__formTitle{
    color: #0C0C0C;
    font-size: 24px;
    font-weight: 700;
}
.reviewTab__formStar{
    color: #FF924A;
    font-size: 32px;
}
.form__labelName{
    display: block;
    color: #434447;
    font-size: 14px;
    font-weight: 400;
}
.technicalTab__input > input{
    width: 100%;
    height: 48px;
    background-color: #fff;
    border: 1px solid #EAECEE;
    border-radius: 8px;
    padding: 0px 12px;
    outline: none;
}
.technical__dragDrop{
    width: 100%;
    height: 68px;
    border-radius: 8px;
    border: 1px dashed #1071FF;
    background-color: #FFF;
    color: #1071FF;
    font-size: 16px;
    font-weight: 400;
}
.technicalTab__formBtn{
    width: 100%;
    height: 48px;
    border-radius: 30px;
    background-color: #1071FF;
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
}
@media (min-width:1025px) and (max-width:1279px) {
    .reviewTab__reviews{
        width: 100%;
    }
}
@media only screen and  (max-width: 700px) {
    .reviewTab__reviews{
        width: 100%;
    }
}

/********** AboutProductTab style **********/

.aboutProductTab__title{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}
.aboutProductTab__input > label{
    color: #434447;
    font-size: 14px;
    font-weight: 400;
    display: block;
}
.aboutProductTab__input > input{
    width: 100%;
    height: 48px;
    background-color: #fff;
    outline: none;
    padding: 0px 16px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
}
.aboutProductTab__inputReq > label{
    color: #434447;
    font-size: 14px;
    font-weight: 400;
    display: block;
}
.aboutProductTab__inputReq > input{
    width: 100%;
    height: 105px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #EAECEE;
}
.aboutProductTab__btn > button{
    width: 467px;
    height: 48px;
    background-color: #1071FF;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    border-radius: 30px;
}


/* MobileTab style */

.productMobile__container{
    width: 100%;
}

.desConent__para{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.desContent__detail{
    width: 300px;
    background:  #F6F8FB;
}
.desContent__detailList > li{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0px;
}
.techTitle{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}
.techTitle__dark{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 600;
}
.tech__Content{
    height: 40px;
    width: 100%;
}
.tech__titleContent{
    background: #F6F8FB;
    width: 100%;
    height: 40px;
}
.emptyCart__image{
    width: 100%;
    height: 478px;
}
.emptyCart__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.emptyCart__para{
    color: #3A3A3A;
    font-size: 20px;
    font-weight: 500;
}
.emptyCart__shopBtn{
    width: 360px;
    height: 55px;
}
.emptyCart__shopBtn > button{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background-color: #1071FF;
    color: #EAECEE;
    font-size: 16px;
    font-weight: 600;
}
@media only screen and (max-width: 425px) {
    .emptyCart__image{
        width: 250px;
        height: 160px;
    }
    .emptyCart__shopBtn{
        width: 200px;
        height: 45px;
    }
    .emptyCart__para{
        font-size: 16px;
    }
    .emptyCart__para{
        font-size: 16px;
    }

}
@media (min-width: 426px) and (max-width: 640px) {
    .emptyCart__image{
        width: 350px;
        height: 200px;
    }
    .emptyCart__para{
        font-size: 16px;
    }
    .emptyCart__shopBtn{
        width: 200px;
        height: 45px;
    }

}
@media (min-width:500px) and (max-width:640px) {
    .emptyCart__container{
        padding: 0px 40px;
    }
    .emptyCart__para{
        font-size: 16px;
    }
    .emptyCart__shopBtn{
        width: 250px;
        height: 45px;
    }
}
@media (min-width:641px) and (max-width:768px) {
    .emptyCart__image{
        width: 350px;
        height: 200px;
    }
    .emptyCart__container{
        padding: 0px 60px;
    }

}
@media (min-width:769px) and (max-width:1024px) {
    .emptyCart__image > img{
        object-fit: fill;
    }
    .emptyCart__image{
        height: 400px;
    }
}
@media (min-width:1280px) and (max-width:1535px) {
    .emptyCart__image > img{
        object-fit: fill;
    }
    .emptyCart__image{
        height: 430px;
    }
}
.accountInform__container{
    border-radius: 8px;
    background: #E6EFFB;
    padding: 34px 30px;
    width: 499px;
}
.accountInform__form > span{
    display: block;
}
.accountInform__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.accountInform__title{
    color: #0C0C0C;
    font-size: 20px;
    font-weight: 700;
}
.accountInform__editIcon{
    color: #1071FF;
}
.accountInform__formName{
    color:#0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.accountInform__nameBold{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 500;
}
.passChange__color{
    color: #0E5DC1;
    font-size: 16px;
    font-weight: 400;
    text-decoration-line: underline;
}
.passChange__input > label{
    display: block;
    color: #434447;
    font-size: 14px;
    font-weight: 400;
}
.passChange__input > input{
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    background-color: #FFF;
    outline: none;
    padding: 0px 12px;
} 
.passChange__cancel{
    width: 150px;
    height: 48px;
    border-radius: 30px;
    background-color: #F6F8FB;
    color: #0E5DC1;
    border: none;
    outline: none;
}
.passChange__change{
    width: 150px;
    height: 48px;
    border-radius: 30px;
    background-color: #1071FF;
    color: #fff;
    border: none;
    outline: none;
}
.accountInform__passChange{
    cursor: pointer;
}
@media only screen and (max-width: 1279px) {
    
}
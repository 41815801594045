@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.welcome {
    color: #0C1421;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.36px;
}

.description {
    color: #313957;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.16px;
}

/* Background Image  */

.background {
    width: 1183px;
    height: 921.393px;
    flex-shrink: 0; 
}

.background img {
    width: 100%;
    height: 100%;
}

/* Form  */
.name {
    color: #0C1421;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
}

.email {
    color: #0C1421;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    /* 15px */
    letter-spacing: 0.15px;
}

.emailbox {
    width: 388px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #D4D7E3;
    background: #F7FBFF;
}

.emailplaceholder {
    color: #8897AD;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.14px;
}

.password {
    color: #0C1421;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
}

.passwordbox {
    width: 388px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #D4D7E3;
    background: #F7FBFF;
}

.passwordplaceholder {
    color: #8897AD;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.14px;
}
@media only screen and (max-width: 400px) {
    .passwordbox, .emailbox, .signinbox   {
        width: 100%;
    }
}
/* Forgot Password  */

.forgot {
    color: #1E4AE9;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
}

/* Sigin In  */
.signinbox {
    width: 100%;
    display: flex;
    padding: 16px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: #1071FF;
}

.signintext {
    color: #FFF;
    /* text-align: center; */
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.16px;
}

/* Signup  */
.signupbox {
    color: #313957;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.16px;
}

.signup {
    color: #1E4AE9;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.16px;
}

/* CopyRight  */
.copyrights {
    color: #959CB6;
    /* text-align: center; */
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.16px;
}
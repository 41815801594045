@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');


/* BACKGROUND IMAGE  AND TAB NAVIGATIONS*/
/* .backgroundimg {
    background-image: url({``});

} */

.backgroundimg{
    background-color: #1071FF;
}
.products {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    /* 43.5px */
}
.search__IconPro{
    color: #fff;
    font-size: 22px;
}

.manufacturers {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    /* 43.5px */
}

.tabline {
    width: 136px;
    height: 6px;
    border-radius: 12px;
    background: #FFF;
}

.tabline2 {
    width: 226px;
    height: 6px;
    border-radius: 12px;
    background: #FFF;
}

.box {
    display: inline-flex;
    padding: 4px 6px 3px 35px;
    justify-content: flex-end;
    align-items: center;
    border-radius: 70px;
    border: 1px solid #EAECEE;
    background: #FFF;
}

.product__SearchBox {
    color: #737B7D;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: 972px;
}

@media only screen and (max-width:320px) {
    .product__SearchBox {
        width: 100px;
        font-size: 10px;
    }

    .box {
        height: 40px;
    }

    .searchbox {
        width: 95px;
        height: 20px;
    }

    .searchtext {
        font-size: 10px;
    }

    .products {
        font-size: medium;
    }

    .manufacturers {
        font-size: medium;
    }

    .tabline {
        width: 70px;
    }

    .bestline {
        font-size: 5px;
    }

    .smart {
        font-size: 5px;
    }

    .line {
        width: 100px;
    }

}

@media (min-width:321px) and (max-width:375px) {
    .product__SearchBox {
        width: 200px;
        font-size: 16px;
    }

    .box {
        margin-top: 13px;
        height: 60px;
    }

    .searchbox {
        height: 50px;
    }

    .searchtext {
        font-size: 10px;
    }

    .products {
        font-size: medium;
    }

    .manufacturers {
        font-size: medium;
    }

    .tabline {
        width: 70px;
    }
}

@media (min-width:376px) and (max-width:425px) {
    .product__SearchBox {
        width: 200px;
        font-size: 16px;
    }

    .box {
        margin-top: 5px;
        height: 55px;
    }

    .searchbox {
        height: 45px;
    }

    .searchtext {
        font-size: 10px;
    }

    .products {
        font-size: 25px;
    }

    .manufacturers {
        font-size: 25px;
    }

    .tabline {
        width: 110px;
    }
}

@media (min-width:426px) and (max-width:768px) {
    .product__SearchBox {
        width: 500px;
    }

    .box {
        margin-top: 30px;
        height: 70px;
    }

    .searchbox {
        height: 60px;
    }

    .searchtext {
        font-size: 10px;
    }

    .products {
        font-size: 25px;
    }

    .manufacturers {
        font-size: 25px;
    }

    .tabline {
        width: 115px;
    }

    .slide {
        width: 100%;
    }
}

@media (min-width:769px) and (max-width:1024px) {
    .product__SearchBox {
        width: 700px;

    }

    .box {
        height: 70px;
    }

    .searchbox {
        height: 60px;
    }

    .searchtext {
        font-size: 10px;
    }
}

@media (min-width:1025px) and (max-width:1440px) {
    .product__SearchBox {
        width: 1000px;

    }

    .box {
        height: 70px;
    }

    .searchbox {
        height: 65px;
    }

    .searchtext {
        font-size: 10px;
    }
}

@media (min-width:1441px) and (max-width:2560px) {
    .product__SearchBox {
        width: 1000px;

    }

    .box {
        height: 70px;
    }

    .searchbox {
        height: 65px;
    }

    .searchtext {
        font-size: 10px;
    }
}

@media (min-width:426px) and (max-width:670px) {
    .product__SearchBox{
        width: 250px;
    }
}
@media (min-width:769px) and (max-width:872px) {
    .product__SearchBox{
        width: 550px;
    }
}
@media (min-width:1025px) and (max-width:1200px) {
    .product__SearchBox{
        width: 800px;
    }
}
@media (min-width:1440px) and (max-width:2560px) {
    .product__SearchBox{
        width: 800px;
    }
}
.searchbox {
    display: flex;
    padding: 16px 21px 17px 21px;
    justify-content: center;
    align-items: center;
    border-radius: 70px;
    background: #1071FF;
}

.searchtext {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.faq {
    display: inline-flex;
    padding: 2px 27px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid #FFF;
}

.faqtext {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
}

/* Carousel Slide  */

.cardwidth {
    width: 1500px;
    height: 316px;
    border-radius: 16px;
}

.bestline {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
}

.smart {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 63px;
    font-weight: 600;
    line-height: 63px;
}

.discount {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
}

.circle {
    width: 500px;
    height: 320px;

}



.bestline {
    color: #FFF;
    font-size: 30px;
    font-weight: 400;
}

.view {
    color: #222;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}

.circle img {
    width: 100%;
    height: 100%;
}

.watch {
    width: 268px;
    height: 270px;
}

.watch img {
    width: 100%;
    height: 100%;
}

/* Shop Catgories */
.shop {
    color: #666;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.category {
    color: #1071FF;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}



.ellipse {
    width: 132px;
    height: 132px;
    flex-shrink: 0;
    fill: var(--Backgorund-1, #F5F5F5);
    stroke-width: 1px;

}
.image:hover{
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05)) drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.05)) drop-shadow(1px 10px 10px rgba(0, 0, 0, 0.04)) drop-shadow(2px 22px 13px rgba(0, 0, 0, 0.03)) drop-shadow(4px 40px 16px rgba(0, 0, 0, 0.01)) drop-shadow(6px 62px 17px rgba(0, 0, 0, 0.00));
    border: 1px solid #0080cc;
}

.ellipse img {
    width: 100%;
    height: 100%;
}

.image {
    width: 55px;
    height: 100px;
}

.image img {
    width: 100%;
    height: 100%;
}

.shoptext {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

/* Grab the Best Deal  */
.grab {
    color: #3A3A3A;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.smart1 {
    color: #1071FF;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.phone {
    width: 90px;
}

.phone img {
    width: 100%;
    height: 100%;
}

.phone2 {
    width: 130px;
}

.phone2 img {
    width: 100%;
    height: 100%;
}

.phone3 {
    width: 140px;
}

.phone3 img {
    width: 100%;
    height: 100%;
}

.phone4 {
    width: 150px;
}



.phone4 img {
    width: 100%;
    height: 100%;

}

.phone5 {
    width: 119px;
}

.phone5 img {
    width: 100%;
    height: 100%;
}

.phone6 {
    width: 119px;
}

.phone6 img {
    width: 100%;
    height: 100%;
}

.label {
    width: 51px;
    height: 53px;
    border-radius: 0px 16px;
    background: #1071FF;
}

.text {
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.line2 {
    width: 201px;
    height: 1px;
    background: #EDEDED;
}

.phonewidth {
    width: 227px;
    height: 295px;
    border-radius: 16px;
    background: #F5F5F5;
}

.galaxy {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.save {
    color: #249B3E;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.n1 {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
}

.n2 {
    color: #222;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

/* Daily Essentials  */
.daily {
    color: #666;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

.essential {
    color: #008ECC;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}


.dailytext {
    color: #666;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.dailydiscount {
    color: #222;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
}





@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');
.categories__content{
    border-radius: 16px;
}
.categories__title{
    color: #2580FF;
    font-size: 26px;
    font-weight: 500;
}
.categories__show{
    color: #1071FF;
    font-size: 16px;
    font-weight: 400;
}
.categories__img{
    height: 300px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
.categories__img img{
    width: 100%;
    height: 100%;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
.categories__name{
    display: grid;
    place-content: center;
    height: 54px;
    color: #fff;
    background-color: #2580FF;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
@media only screen and (max-width:320px) {
    .categories__container{
        padding: 0px 20px;
    }
    .categories__img{
        height: 250px;
    }
}
@media (min-width:321px) and (max-width:375px) {
    .categories__container{
        padding: 0px 40px;
    }
    .categories__img{
        height: 250px;
    }
}
@media (min-width:376px) and (max-width:425px) {
    .categories__container{
        padding: 0px 50px;
    }
    .categories__img{
        height: 250px;
    }
}
@media (min-width:426px) and (max-width:500px) {
    .categories__container{
        padding: 0px 80px;
    }
    .categories__img{
        height: 250px;
    }
}
@media (min-width:501px) and (max-width:550px) {
    .categories__container{
        padding: 0px 100px;
    }
    .categories__img{
        height: 250px;
    }
}
@media (min-width:551px) and (max-width:639px) {
    .categories__container{
        padding: 0px 140px;
    }
    .categories__img{
        height: 250px;
    }
}
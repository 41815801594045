@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap");
.nav__section {
  width: 100%;
  height: 82px;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.1);
}
nav > ul > li > a,
.navV__text {
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}
.search__box {
  width: 250px;
  height: 48px;
  border: 1px solid #eaecee;
  padding: 0px 16px;
  border-radius: 8px;
}
.search__box input {
  background-color: transparent;
  outline: none;
  width: 100%;
}
.fa-magnifying-glass {
  font-size: 12px;
  color: #1071ff;
}
.search__box > input::placeholder {
  color: #737b7d;
  font-size: 14px;
  font-weight: 400;
}
@media only screen and (max-width: 321px) {
  .search__box {
    width: 100%;
    height: 38px;
  }
}
@media (min-width: 321px) and (max-width: 375px) {
  .search__box {
    width: 100%;
    height: 38px;
  }
}
@media (min-width: 376px) and (max-width: 425px) {
  .search__box {
    width: 100%;
    height: 38px;
  }
}
@media (min-width: 426px) and (max-width: 640px) {
  .search__box {
    width: 100%;
    height: 43px;
  }
}
@media (min-width: 641px) and (max-width: 768px) {
  .search__box {
    width: 100%;
    height: 43px;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .nav__section {
    display: flex;
    justify-content: center;
  }
  .search__box{
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .search__box {
    width: 300px;
  }
}

/*************** navV section style *************/

.navV__section {
  width: 100%;
  height: 82px;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.1);
}
.navV__text {
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
}
.navV__searchContainer {
  width: 500px;
  height: 48px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eaecee;
  align-items: center;
  padding: 0px 12px;
}
.navV__searchContainer .navV__input > input {
  width: 100%;
  height: 100%;
  outline: none;
}
@media only screen and (max-width: 500px) {
  .navV__searchContainer {
    width: 100%;
  }
}

.addCard__productList{
    width: 100%;
    height: 48px;
    background-color: #F6F8FB;
    padding: 0px 24px;
}
.addCard__content{
    background-color: #F6F8FB;
}
.addCard__productDetail{
    background-color: #fff;
    border-radius: 8px;
    
}
.addCard__product{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 600;
}
.addCard__items{
    color: #0C0C0C;
    font-size: 12px;
    font-weight: 500;
}
.arrowUp{
    color: #1071FF;
    font-size: 20px;
}
.addCard__Image{
    width: 137px;
    height: 112px;
    position: relative;
}
.addCard__star{
    width: 24px;
    height: 24px;
    background-color: #E6EFFB;
    border-radius: 50%;
    color: #1071FF;
    font-size: 14px;
    position: absolute;
    right: 0;
}
.addCard__Image > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.addCard__brand{
    color: #737B7D;
    font-size: 14px;
    font-weight: 400;
}
.addCard__brandNet{
    color: #0C0C0C;
    font-size: 24px;
    font-weight: 300;
}
.addCard__delivery{
    color: #3DB62A;
    font-size: 14px;
    font-weight: 400;
}
.addCard__heading{
    color: #0C0C0C;
    font-size: 20px;
    font-weight: 700;
}
.addCard__brandPrice{
    color: #1071FF;
    font-size: 24px;
    font-weight: 700;
}
.addCard__minus{
    width: 92px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    background-color: #FFF;
}
.addCard__num{
    width: 73px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    background-color: #FFF;
}
.addCard__plus{
    width: 92px;
    height: 48px;
    background-color: #1071FF;
    color: #fff;
    border-radius: 8px;
}
.addCard__deleteBtn > button{
    color: #434447;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.addCard__priceContainer{
    background-color: #F6F8FB;
}
.addCard__download > button{
    color: #1071FF;
    font-size: 18px;
    font-weight: 600;
}
.addCard__removeBtn > button{
    font-size: 18px;
    font-weight: 600;
}
.addCard__pricenet{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 700;
}
.addCard__totalPrice{
    color: #1071FF;
    font-size: 24px;
    font-weight: 700;
}
.summary__container{
    background-color: #F6F8FB;
    height: fit-content;
}
.summary__title{
    color: #0C0C0C;
    font-size: 28px;
    font-weight: 700;
}
.summary__productList__title{
    color: #1071FF;
    font-size: 20px;
    font-weight: 600;
}
.summary__productList__priceNet{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}
.summary__itemList__title{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 500;
}
.summary__itemList__priceNet{
    color: #1071FF;
    font-size: 14px;
    font-weight: 600;
}
.summary__product{
    background-color: #fff;
}
.summary__totalPrice__title{
    color: #1071FF;
    font-size: 24px;
    font-weight: 700;
}
.summary__totalPrice__rupees{
    color: #1071FF;
    font-size: 24px;
    font-weight: 700;
}
.summary__checkoutBtn > button{
    width: 100%;
    height: 48px;
    background-color: #1071FF;
    border-radius: 30px;
    color: #EAECEE;
    font-size: 16px;
    font-weight: 400;
}
.summary__shoppingBtn > button{
    width: 100%;
    height: 48px;
    border-radius: 30px;
    border-radius: 30px;
    border: 2px solid #1071FF;
    background: #FFF;
    font-size: 16px;
    font-weight: 400;
    color: #1071FF;     
}
.locationDot__icon{
    font-size: 20px;
    color: #1071FF;
}
@media only screen and (max-width:320px) {
    .addCard__brand{
        font-size: 14px;
    }
    .addCard__heading{
        font-size: 16px;
        font-weight: 700;
    }
    .addCard__brandNet{
        font-size: 16px;
        font-weight: 400;
    }
    .addCard__brandPrice{
        font-size: 20px;
        font-weight: 600;
    }
    .addCard__deleteBtn{
        font-size: 14px;
        font-weight: 500;
    }
    .addCard__Img{
        width: 64px;
        height: 53px;
    }
    .addCard__Image > img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .addCard__star{
        width: 16px;
        height: 16px;
    }
    .addCard__minus, .addCard__plus{
        width: 40px;
        height: 30px;
    }
    .addCard__num{
        width: 30px;
        height: 30px;
    }
    .addCard__download > button, .addCard__removeBtn > button{
        font-size: 14px;
        font-weight: 500;
    }
    .priceNet{
        font-size: 14px;
        font-weight: 700;
    }
    .priceDetail{
        font-size: 18px;
        font-weight: 600;
    }
    .addCard__totalPrice{
        font-size: 18px;
        font-weight: 700;
    }
}
@media (min-width:321px) and (max-width:640px) {
    .addCard__brand{
        font-size: 14px;
    }
    .addCard__heading{
        font-size: 16px;
        font-weight: 700;
    }
    .addCard__brandNet{
        font-size: 16px;
        font-weight: 400;
    }
    .addCard__brandPrice{
        font-size: 20px;
        font-weight: 600;
    }
    .addCard__deleteBtn{
        font-size: 14px;
        font-weight: 500;
    }
    .addCard__Img{
        width: 64px;
        height: 53px;
    }
    .addCard__Image > img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .addCard__star{
        width: 16px;
        height: 16px;
    }
    .addCard__minus, .addCard__plus{
        width: 60px;
        height: 38px;
    }
    .addCard__num{
        width: 50px;
        height: 38px;
    }
    .addCard__download > button, .addCard__removeBtn > button{
        font-size: 14px;
        font-weight: 500;
    }
    .priceNet{
        font-size: 14px;
        font-weight: 700;
    }
    .priceDetail{
        font-size: 18px;
        font-weight: 600;
    }
    .addCard__totalPrice{
        font-size: 18px;
        font-weight: 700;
    }
}
@media (min-width:1025px) and (max-width:1279px) {
    .addCard__brand{
        font-size: 14px;
    }
    .addCard__heading{
        font-size: 16px;
        font-weight: 700;
    }
    .addCard__brandNet{
        font-size: 16px;
        font-weight: 400;
    }
    .addCard__brandPrice{
        font-size: 17px;
        font-weight: 600;
    }
    .addCard__deleteBtn{
        font-size: 14px;
        font-weight: 500;
    }
    .addCard__Img{
        width: 64px;
        height: 53px;
    }
    .addCard__Image > img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .addCard__star{
        width: 16px;
        height: 16px;
    }
    .addCard__minus, .addCard__plus{
        width: 60px;
        height: 38px;
    }
    .addCard__num{
        width: 50px;
        height: 38px;
    }
    .addCard__download > button, .addCard__removeBtn > button{
        font-size: 14px;
        font-weight: 500;
    }
    .priceNet{
        font-size: 14px;
        font-weight: 700;
    }
    .priceDetail{
        font-size: 18px;
        font-weight: 600;
    }
    .addCard__totalPrice{
        font-size: 18px;
        font-weight: 700;
    }
}
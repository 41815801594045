.myaccount__navigation{
    width: 300px;
    height: 281px;
    border-radius: 8px;
    background-color:  #F6F8FB;
}
.myaccount__title{
    color: #0C0C0C;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #CFD2D5;
    padding: 20px 0px;
}
.myaccount__profileBtn > button{
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 13px 0px;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
}
@media only screen and (max-width:768px) {
    .myaccount__navigation{
        background-color:  #F6F8FB;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');

.gstt{
    width: 100%;
    height: 599px;
    background: #EFEFEF;
}
.gst__container{
    width: 920px;
    height: 439px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
.create__content{
    width: 374px;
}
.gst__titlee{
    color: #1071FF;
    font-size: 22px;
    font-weight: 500;
}
.ratio{
    display: block;
}
.no{
    color: #434447;
    font-size: 14px;
    font-weight: 400;
}
#gstInput, #panInput{
    width: 373px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    outline: none;
    padding: 0px 12px;
}
.subBtn{
    width: 100%;
    height: 42px;
    background-color: #1071FF;
    color: #fff;
    border-radius: 30px;
}
.subBtn > button{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width:920px) {
    .gst__container{
        width: 100% !important;
    }
    #gstInput, #panInput{
        width: 100%;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');
.discount__section{
    background-color: #1071FF;
}
.discount__img{
    width: 113px;
    height: 140px;
}
.discount__img img{
    width: 100%;
    height: 100%;
}
.discount__num{
    color: #FFF;
    font-size: 80px;
    font-weight: 600;
}
.discount__le{
    color: #FFF;
    font-size: 48px;
    font-weight: 500;
    display: inline-block;
    line-height: normal;
}
.discount__para{
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}
.discount__btn > button{
    width: 171px;
    height: 48px;
    background-color: #fff;
    color: #1071FF;
    border-radius: 30px;
}
@media only screen and (max-width:768px) {
    .discount__num{
        font-size: 36px;
    }
    .discount__le{
        font-size: 26px;
    }
}
@media (min-width:1024px) and (max-width:1280px) {
    .discount__num{
        font-size: 42px;
    }
    .discount__le{
        font-size: 26px;
    }
}
@media (min-width:1281px) and (max-width:1536px) {
    .discount__num{
        font-size: 52px;
    }
    .discount__le{
        font-size: 36px;
    }
}


.MuiPaper-elevation1 {
    box-shadow: none !important;
}
.MuiPaper-rounded{
    border-radius: 0px !important;
}
.MuiContainer-maxWidthLg{
    width: 640px !important;
    height: fit-content;
}
.MuiTypography-body2{
    font-family: 'Poppins' 'sans-serif' !important;
}
.MuiPaper-root{
    background-color: transparent !important;
}
.MuiButton-root{
    color: #FFF !important;
    background-color: #1071FF !important; 
}
.checkout__form{
    border-radius: 24px;
    background-color: #FFF;
    box-shadow: 12px 12px 36px 6px rgba(2, 2, 70, 0.12);
}
@media only screen and (max-width:640px) {
    .MuiContainer-maxWidthLg{
        width: 100% !important;
        height: fit-content;
    }
}

/********* Billing style ********/


.billing__container{
    border-radius: 24px;
    background: #FFF;
    box-shadow: 12px 12px 36px 6px rgba(2, 2, 70, 0.12);}
.billing__title{
    color: #1071FF;
    font-size: 14px;
    font-weight: 500;
}
.firstName > label, .billing__email > label, .billing__address > label, .billing__selection > label{
    display: block;
    color: #344054 !important;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 6px !important;
}
.firstName > input, .billing__selection{ 
    width: 258px;
    height: 43px;
    padding: 0px 12px;
    border-radius: 6px;
    border: 1px solid #D0D5DD;
    background-color: #FFF;
    box-shadow: 0px 0.775px 1.55px 0px rgba(16, 24, 40, 0.05);
    outline: none;
}
.billing__email > input, .billing__address > input{
    width: 100%;
    height: 43px;
    padding: 0px 0px 0px 12px;
    border-radius: 6px;
    border: 1px solid #D0D5DD;
    background-color: #FFF;
    box-shadow: 0px 0.775px 1.55px 0px rgba(16, 24, 40, 0.05);
    outline: none;
}
.billing__selection > select{
    width: 100%;
    height: 100%;
    background-color: transparent;
}
.checkout__backBtn{
    width: 120px;
    height: 38px;
    border-radius: 6;
    border: 0.775px solid #D0D5DD;
    background-color: #FFF;
    box-shadow: 6px 6px 18px 0px rgba(2, 2, 70, 0.15);
    color: #344054 !important;
    font-size: 12px;
    font-weight: 500;
}
.checkout__nextBtn{
    width: 120px;
    height: 38px;
    border-radius: 6;
    border: 0.775px solid #D0D5DD;
    background-color: #1071FF;
    box-shadow: 6px 6px 18px 0px rgba(2, 2, 70, 0.15);
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
}
.billing__selection > select{
    color: #344054 !important;
    font-size: 11px;
    font-weight: 500;
    outline: none;
}
.checkBack__btn{
    width: 160px;
    height: 37px;
    border-radius: 6px;
}
.checkNext__btn{
    width: 160px;
    height: 37px;
    background-color: #fff !important;
    color: #1071FF !important;
    border-radius: 6px;
    border: 1px solid #D0D5DD;
    box-shadow: 6.2px 6.2px 18.6px 0px rgba(2, 2, 70, 0.15);
}
@media only screen and (max-width:640px) {
    .firstName > input, .billing__selection{
        width: 100%;
    }
    
}

.productImg__Slider__Container{
    width: 100px;
    height: 490px;
    position: relative;
    overflow: hidden;
}
.productImg__slider{
    overflow-y: hidden;
    padding: 40px 0px;
    scroll-behavior: smooth;
}
.productImg__sliderImg{
    width: 87px;
    height: 87px;
    border-radius: 8px;
    border: 1px solid #E6EFFB;
}
.productImg__sliderImg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}
.pre__btn, .next__btn{
    position: absolute;
}
.next__btn{
    bottom: 0;
}
.productImg__image{
    width: 515px;
    height: 490px;
    position: relative;
    border-radius: 8px;
    border: 1px solid #E6EFFB;
}
.productImg__price{
    position: absolute;
}
.productImg__image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}
.productImg__sliderImg.clicked{
    border: 1px solid #1071FF;
}
.productImg__discount{
    width: 52px;
    height: 32px;
    background-color: #FF7E27;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
}
.productImg__star{
    width: 32px;
    height: 32px;
    background-color: #E6EFFB;
    border-radius: 50%;
    font-size: 24px;
    color: #1071FF;
}
.productarrow{
    font-size: 22px;
    color: #000;
}
@media only screen and (max-width: 640px) {
    .productImg__Slider__Container{
        width: 100%;
        height: 100px;
        display: flex;
    }
    .pre__btn, .next__btn{
        position: relative;
    }
    .productImg__slider{
        padding: 10px 0px;
    }
    .productImg__sliderImg{
        width: 100%;
        height: 77px;
    }
    .pre__btn, .next__btn{
        transform: rotate(-90deg);
    }
    .productImg__image{
        width: 100%;
        height: 375px;
    }
    .productImg__image > img{
        width: 100%;
        height: 100%;
    }
}

/************** Product__Detail style ************/

.productDetail__title{
    color: #0C0C0C;
    font-size: 28px;
    font-weight: 500;
}
.productDetail__partNo{
    color: #737B7D;
    font-size: 18px;
    font-weight: 400;
}
.rating__star{
    color: #FF924A;
    font-size: 18px;
}
.productDetail__stock{
    color: #00893A;
    font-size: 14px;
    font-weight: 400;
}
.productDetail__price{
    color: #1071FF;
    font-size: 30px;
    font-weight: 600;
}
.productDetail__strick{
    color: #FF7E27;
    font-size: 24px;
    font-weight: 500;
}
.add__btn{
    width: 260px;
    height: 48px;
    border-radius: 30px;
    background-color: rgba(199, 199, 199, 0.50);
    color: rgba(42, 42, 42, 0.90);
    font-size: 16px;
    font-weight: 600;
}
.buy__btn{
    width: 260px;
    height: 48px;
    border-radius: 30px;
    background-color: #1071FF;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
}
.share__btn{
    width: 44px;
    height: 43px;
    border-radius: 8px;
    border: 1px solid #1071FF;
    color: #1071FF;
    font-size: 24px;
}
.minus__btn{
    width: 92px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    font-size: 32px;
}
.count__no{
    width: 73px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
}
.plus__btn{
    width: 92px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    color: #fff;
    background-color: #1071FF;
    font-size: 26px;
}
.description__title{
    color: #1071FF;
    font-size: 18px;
    font-weight: 600;
}
.description__para{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}
@media only screen and (max-width:375px) {
    .productDetail__price{
        font-size: 22px;
    }
    .productDetail__strick{
        font-size: 18px;
    }
    .share__btn{
        font-size: 18px;
        height: 35px;
    }
    .add__btn, .buy__btn{
        width: 100px;
        height: 40px;
    }
}
@media (min-width:376px) and (max-width:425px) {
    .productDetail__price{
        font-size: 26px;
    }
    .productDetail__strick{
        font-size: 20px;
    }
    .share__btn{
        font-size: 18px;
        height: 35px;
    }
    .add__btn, .buy__btn{
        width: 120px;
        height: 48px;
    }

}
@media (min-width:426px) and (max-width:499px) {
    .add__btn, .buy__btn{
        width: 130px;
        height: 48px;
    }
}
@media (min-width: 500px) and (max-width:640px) {
    .productView{
        padding: 0px 40px;
    }
    .add__btn, .buy__btn{
        width: 150px;
        height: 48px;
    }

}
@media (min-width: 641px) and (max-width:767px){
    .add__btn, .buy__btn{
        width: 200px;
        height: 48px;
    }
}
@media (min-width:1024px) and (max-width:1280px) {
    .add__btn, .buy__btn{
        width: 200px;
        height: 48px;
    }
}
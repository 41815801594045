@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');


.create{
    width: 100%;
    height: 700px;
    background: #EFEFEF;
}
.create__container{
    width: 920px;
    height: 587px;
    background-color: #fff;
    display: flex;
    justify-content: center;
}
.create__content{
    width: 374px;
}
.create__title{
    color: #1071FF;
    font-size: 22px;
    font-weight: 500;
}
.email > label, label{
    color: #434447;
    font-size: 14px;
    font-weight: 400;
}
.email > input{
    width: 100%;
    height: 42px;
    border: 1px solid #ececee;
    border-radius: 8px;
    outline: none;
    padding: 0px 14px;
}
.select__state{
    width: 100%;
    height: 42px;
    border: 1px solid #ececee;
    border-radius: 8px;
    outline: none;
    padding: 0px 14px;
}
.select__state > select {
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #434447;
    font-size: 14px;
    font-weight: 400;
    outline: none;
}
.subBtn{
    width: 100%;
    height: 42px;
    background-color: #1071FF;
    color: #fff;
    border-radius: 30px;
}
.subBtn > button {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width:425px) {
    .create{
        height: 600px;
    }
    .create__container{
        width: 100% !important;
        height: 520px !important;
    }
    .create__content{
        width: 100% !important;
    }
}
@media (min-width:426px) and (max-width: 640px) {
    .create{
        height: 600px;
    }
    .create__container{
        width: 100% !important;
        height: 520px !important;
    }
}
@media (min-width:641px) and (max-width: 920px){
    .create__container{
        width: 100% !important;
        height: 540px !important;
    }
}
.riva__header{
    margin-top: 28px;
}

.riva__header >  header{
    display: flex;
}

.riva-header__logo{
     width: 200px;
     height: 60px;
     display: flex;
     justify-content: center;
     align-items: center;
}

.riva-header__logo__img{
    width: 117px;
    height: 35px;
}

.riva-header__logo__img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.riva-header__navbar{
    margin-left: 51px;
}

.riva-header__title{
    color: #1071FF;
    font-size: 18px;
    font-weight: 600;
}

.riva-header__contact{
    font-size: 16px;
    font-weight: 400;
    color: #3a3a3a;
}

.riva-header__location{
    display: flex;
}

.riva__gst{
    display: flex;
    align-items: center;
}

.icon__ColrBlue{
    color: #1071FF;
}

.riva-gst__content{
    width: 16px;
    height: 16px;
}

.riva-gst__content img{
    width: 100%;
    height: 100%;
}

.riva__trust{
    display: flex;
    align-items: center;
}

.riva-trust__img{
    width: 16px;
    height: 16px;
}

.riva-trust__img img{
    width: 100%;
    height: 100%;
}

.riva__rate{
    display: flex;
}

.riva-rate__no{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #1071FF;
}

.riva-email{
    display: flex;
    align-items: center;
}

.riva-rate__star{
    color: #FDC040;
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.dark__{
    color: black;
}

.riva-navbar__search{
    display: flex;
    column-gap: 22px;
}

.riva-search__input input{
    width: 455px;
    height: 42px;
    border-radius: 2px 0px 0px 2px;
    background: #F3F3F3;
    padding: 12px;
    border: none;
    outline: none;
}

.riva-search__icons{
    display: grid;
    place-content: center;
    width: 55px;
    height: 42px;
    background-color: #274B85;
    color: #fff;
    font-size: 24px;
}

@media only screen and (max-width:640px) {
    .riva-search__input input{
        width: 100%;
    }
    .riva-header__navbar{
        margin-left: 0px;
    }
}

@media (min-width:426px) and (max-width: 1024px) {
    .riva-header__title{
        text-align: center;
    }
    
}

@media (min-width:1024px) and (max-width:1280px) {
    .riva-search__input input{
        width: 300px;

    }
}





/****** Riva Product style *******/


.rivaProduct{
    background: #F6F8FB;
}

.riva-ourProduct__title{
    color: #1071FF;
    font-size: 26px;
    font-weight: 500;
}

.riva-ourProduct__list{
    color: #1071FF;
    font-size: 16px;
    font-weight: 400;
}

.arrow-prod{
    font-size: 22px;
    font-weight: 500;
}

.riva-product{
    width: 100%;
    height: fit-content;
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid #F6F8FB;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.riva-product__dis{
    width: 52px;
    height: 32px;
    display: grid;
    place-content: center;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    background-color: #FF7E27;
}

.riva-product__star{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    background-color: #E6EFFB;
    color: #1071FF;
    font-size: 18px;
}

.riva-product__image{
    width: 100%;
    height: 200px;
}

.riva-product__image > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.riva-product__stock{
    color: #00893A;
    font-size: 13px;
    font-weight: 400;
}

.riva-product__share{
    color: #1071FF;
    font-size: 20px;
}

.riva-product__num{
    color: #737B7D;
    font-size: 14px;
    font-weight: 400;
}

.riva-product__title{
    color: #0C0C0C;
    font-size: 20px;
    font-weight: 500;
    height: 100px;
}

.riva-product__Yourprice{
    color: #1071FF;
    font-size: 14px;
    font-weight: 600;
}

.riva-product__grossNet{
    color: #1071FF;
    font-size: 22px;
    font-weight: 600;
    line-height: 16px;
}

.riva-product__net{
    color: #1071FF;
    font-size: 14px;
    font-weight: 600;
}

.strike-net{
    color: #FF7E27;
    font-size: 16px;
    font-weight: 400;
}

.riva-product__strike{
    color: #FF7E27;
    font-size: 18px;
    font-weight: 400;

}

.riva-product__btn > button{
    width: 100%;
    height: 48px;
    border-radius: 30px;
    background-color: #1071FF;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
}


/****** Riva slider style *******/

.swiper {
    width: 100%;
    height: 600px;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slick-slide{
    padding: 20px 0px;
}

.slick-slide > div{
    margin: 0px 10px;
}

.slick-next, .slick-prev{
    background-color: red;
}

@media only screen and (max-width: 425px) {
    .swiper{
        height: 200px;
    }
    .slick-next{
        right: -20px;
    } 
    .slick-prev{
        left: -20px;
    }
    .riva-ourProduct__title{
        font-size: 18px;
    }
    .riva-ourProduct__list {
        font-size: 14px;
    }
    .arrow-prod{
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .rivaProduct-slider{
        padding: 0px 40px;
    }
}

@media (min-width:481px) and (max-width: 640px) {
    .rivaProduct-slider{
        padding: 0px 20px;
    }
}

@media (min-width:426px) and (max-width:640px) {
    .swiper{
        height: 250px;
    }
    .riva-ourProduct__title{
        font-size: 18px;
    }
    .riva-ourProduct__list {
        font-size: 14px;
    }
    .arrow-prod{
        font-size: 16px;
    }

}

@media (min-width:641px) and (max-width:768px) {
    .swiper{
        height: 300px;
    }
    .rivaProduct-slider{
        padding: 0px 20px;
    }
    .slick-next{
        right: -20px;
    } 
    .slick-prev{
        left: -20px;
    }
    .riva-ourProduct__title{
        font-size: 22px;
    }
    .riva-ourProduct__list {
        font-size: 16px;
    }
    .arrow-prod{
        font-size: 20px;
    
    }
}

@media (min-width:769px) and (max-width:880px) {
    .slick-next{
        right: -20px;
    } 
    .slick-prev{
        left: -20px;
    }
}

@media (min-width:881px) and (max-width:1023px) {
    .slick-next{
        right: -40px;
    } 
    .slick-prev{
        left: -40px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .swiper{
        height: 400px;
    }
    
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .swiper{
        height: 450px;
    }
}

@media (min-width: 1281px) and (max-width: 1535px) {
    .swiper{
        height: 500px;
    }
}


/****** About style ******/

.riva-about__title{
    color: #3a3a3a;
    font-size: 32px;
    font-weight: 600;
}

.riva-about__para{
    color: #3A3A3A;
    font-size: 18px;
    font-weight: 400;
}

.riva-about__container{
    width: 1300px;
}

@media only screen and (max-width :640px) {
    .riva-about__title{
        font-size: 24px;
    }
    .riva-about__para{
        font-size: 14px;
    }
}

@media (min-width: 641px) and (max-width : 768px) {
    .riva-about__title{
        font-size: 28px;
    }
    .riva-about__para{
        font-size: 16px;
    }
}

@media only screen and (max-width: 1301px) {
    .riva-about__container{
        width: 100%;
    }
}


/****** Rating & Reviews style *******/

.rivaDetail{
    background: #F6F8FB;
}
.riva-rating__detailTitle{
    color: #000;
    font-size: 32px;
    font-weight: 600;
}
.riva-rating__box{
    padding: 21px 36px 34px 28px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.riva-rating__imgDetails{
    width: 100%;
    height: 55px;
    margin-bottom: 24px;
}
.riva-rating__img{
    width: 55px;
    height: 55px;
}
.riva-rating__img > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.riva-rating__detailTitle{
    color: #000;
    font-size: 15px;
    font-weight: 400;
}
.riva-rating__detailName{
    color: #676767;
    font-size: 12px;
    font-weight: 400;
}
.riva-rating__detailStar{
    color: #E67136;
}


/***** Riva Form style ******/

.rivaForm{
    background: #F6F8FB;
}
.rivaForm__container{
    border-top: 1px solid #e7e4e4;
    border-bottom: 1px solid #e7e4e4;
    padding: 40px 0px 40px;
}
.rivaForm__leftImg{
    width: 117px;
    height: 35px;
}
.rivaForm__leftImg > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.rivaForm__leftAddress{
    display: flex;
    align-items: center;
    margin: 38px 0px;
    color: #253D4E;
    font-size: 16px;
    font-weight: 500;
}
.rivaForm__leftDetail{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 100px;
}
.rivaForm-leftAddress__icon{
    color: #1071FF;
    margin-right: 3px;
}
.rivaForm__leftContact{
    display: flex;
    align-items: center;
    margin: 38px 0px;
    color: #253D4E;
    font-size: 16px;
    font-weight: 500;
}
.rivaForm__leftContact__icon{
    color: #1071FF;
    margin-right: 3px;
}
.rivaForm__leftEmail{
    display: flex;
    align-items: center;
    margin-top: 38px;
    color: #253D4E;
    font-size: 16px;
    font-weight: 500;
}
.rivaForm-leftEmail__icon{
    color: #1071FF;
    margin-right: 3px;
}
.rivaForm__leftSocial-icons{
    display: flex;
    margin-top: 70px;
    column-gap: 26px;
    color: #1071FF;
    font-size: 34px;
}
.rivaForm-ul__list{
    margin-top: 38px;
}
.rivaForm-ul__list > li{
    margin-bottom: 30px;
}

.rivaForm__right > label{
    margin: 22px 0px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
}
.rivaForm-right__title{
    color: #1071FF;
    font-size: 18px;
    font-weight: 600;
}

.rivaForm-right__mobile{
    width: 100%;
    height: 52px;
    padding: 12px;
    border-radius: 6px;
   border: 1px solid #9D9D9D;
   background:  #FFF;
}
.rivaForm-right__mobile > input{
   width: 100%;
   height: 100%;
   border: none;
   outline: none;
}
.rivaForm-right__mobile > input::placeholder{
    color:  #9D9D9D;
    font-size: 16px;
    font-weight: 500;
}

.rivaForm-right__detail{
    width: 100%;
    height: 102px;
    border-radius: 6px;
    border: 1px solid #9D9D9D;
    background:  #FFF;
}
.rivaForm-right__detail > input{
    width: 100%;
    outline: none;
    padding: 12px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
.rivaForm-right__detail > input::placeholder{
    color:  #9D9D9D;
    font-size: 16px;
    font-weight: 500;
}
.rivaForm__right > button{
    width: 100%;
    height: 50px;
    background-color: #1071FF;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    font-size: 18px;
    border-radius: 8px;
}
@media only screen and (max-width: 768px) {
    .rivaForm__container{
        padding: 22px 18px;
    }
    .rivaForm__leftImg{
        width: 97px;
        height: 32px;
    }
    .rivaForm__leftImg img{
        width: 100%;
        height: 100%;
    }
    .rivaForm__leftAddress{
        margin: 27px 0px;
    }
    .rivaForm__leftContact{
        margin: 27px 0px;
    }
    .rivaForm__leftEmail{
        margin: 27px 0px;
    }
    .rivaForm__leftSocial-icons{
        margin-top: 30px;
    }
    .rivaForm-ul__list li{
        margin-bottom: 27px;
    }
  
}

@media (min-width:769px) and (max-width:1024px) {
    .rivaForm__container{
        padding: 28px;
    }
}


/****** Riva Footer style ******/

.rivafooter{
    background: #F6F8FB;
}
.rivafooter__container{
   padding: 22px 0px;
}
.rivafooter__rights{
    color:  #253D4E;
    font-size: 16px;
    font-weight: 500;
}

.rivafooter__containerImg{
    height: 32px;
    width: 224px;
}
.rivafooter__containerImg > img{
    width: 100%;
    height: 100%;
}
.rivafooter__logo{
    width: 197px;
    height: 35px;
}
.rivafooter__logo > img{
    width: 100%;
    height: 100%;
}
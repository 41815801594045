.orderHistory__table{
    width: 100%;
    text-align: left;
}
.orderHistory__amountDetail{
    cursor: pointer;
    height: 66px;
    border-bottom: 1px solid #EAECEE;;
}
.shippingAddress__container{
    border-radius: 8px;
    background-color: #E6EFFB;
}
.shippingAdd__title, .shippingMethod__title, .shippingPay__title, .billingAddress__title, .orderListproducts__title{
    color: #0C0C0C;
    font-size: 20px;
    font-weight: 700;
}
.orderHistory__title, .orderListproducts__tableTitle{
    height: 32px;
    border-bottom: 2px solid #1071FF;
}
.shippingAdd__detail > span .shippingPay__para{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.shippingAdd__detail > span, .billingAddress__detail > span{
    display: block;
    margin: 20px 0px;
}
.shippingMethod__cost__bold{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 600;
}
.orderListproducts__detail > table{
    width: 100%;
    text-align: left;
}
.orderListproductsdetail__img{
    width: 87px;
    height: 71px;
}
.orderListproductsdetail__img > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.orderListproductsdetail__img__title{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 700;
}
.orderListproducts__detail > table > tr > td{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.orderListproductsdetail__bold{
    color: #434447;
    font-size: 24px;
    font-weight: 600;
}
.print__blue{
    color: #1071FF;
    font-size: 18px;
    font-weight: 400;
}
.orderHistoryPrint__totalTitle{
    color: #3A3A3A;
    font-size: 16px;
    font-weight: 600;
}
.orderHistoryPrint__totalprice{
    color: #1071FF;
    font-size: 24px;
    font-weight: 600;
}
.orderHistory__amountDetailBlue{
    color: #1071FF;
    font-size: 24px;
    font-weight: 600;
}
.checkCircle{
    color: #00893A;
    font-size: 14px;
    font-weight: 600;
}
.checkpending{
    color: #FF7E27;
    font-size: 14px;
    font-weight: 600;
}
.orderHistory__noBold{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 500;
}
.orderHistory__date{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.checkCircle__icon, .pending__icon{
    font-size: 16px;
    font-weight: 800;
}
.orderHistory__height{
    height: 82px;
    border-bottom: 2px solid #ffffff;
}
@media only screen and (max-width: 375px) {
    .orderHistory__title{
        border: none;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');
.features{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 12px;
}
.features__icon{
    width: 32px;
    height: 32px;
    background-color: #1071FF;
    color: #fff;
    border-radius: 4px;
    font-size: 22px;
}
.features__title{
    color: #101828;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}
.features__para{
    color: #475467;
    font-size: 16px;
    font-weight: 400;
}
.features__more{
    color: #1071FF;
    font-size: 16px;
    font-weight: 600;
}
.fa-chevron-right{
    font-size: 12px;
}
@media only screen and (max-width:500px) {
    .features__section{
        padding: 0px 20px;
    }
}
@media (min-width:501px) and (max-width:640px) {
    .features__section{
        padding: 0px 40px;
    }
}
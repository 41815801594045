@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');
.dpd{
    background: #F6F8FB;
}
.dpd__content{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.dpd__title{
    color: #0C0C0C;
    font-size: 36px;
    font-weight: 600;
}
.dpd__para{
    color: #0C0C0C;
    font-size: 16px;
    font-weight: 400;
}
.dpd__btn > button{
    width: 159px;
    height: 48px;
    color:#FFF;
    font-size: 16px;
    font-weight: 400;
    background-color: #1071FF;
    border-radius: 30px;
}
.dpd__img{
    width: 622px;
    height: 329px;
}
.dpd__img img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
@media only screen and (max-width:320px) {
    .dpd__img{
        width: 100%;
        height: 169px;
    }
    .dpd__title{
        font-size: 24px;
        line-height: normal;
    }
    .dpd__para{
        font-size: 14px;
    }
}
@media (min-width:321px) and (max-width:375px) {
    .dpd__img{
        width: 100%;
        height: 199px;
    }
    .dpd__title{
        font-size: 24px;
        line-height: normal;
    }
    .dpd__para{
        font-size: 14px;
    }
}
@media (min-width:376px) and (max-width:425px) {
    .dpd__img{
        width: 100%;
        height: 229px;
    }
    .dpd__title{
        font-size: 24px;
        line-height: normal;
    }
    .dpd__para{
        font-size: 14px;
    }
}
@media (min-width:426px) and (max-width:639px) {
    .dpd__content{
        padding: 0px 40px;
    }
    .dpd__image{
        width: 100%;
        display: grid;
        place-content: center;
    }
    .dpd__img{
        width: 370px;
        height: 269px;
    }
    .dpd__title{
        font-size: 26px;
        line-height: 40px;
    }
   
}   
@media (min-width:640px) and (max-width:767px) {
    .dpd__content{
        padding: 0px 40px;
    }
    .dpd__image{
        width: 100%;
        display: grid;
        place-content: center;
    }
    .dpd__img{
        width: 430px;
        height: 279px;
    }
    .dpd__title{
        font-size: 26px;
        line-height: 40px;
    }
   
}  
@media (min-width:768px) and (max-width:1023px) {
    .dpd__content{
        padding: 0px 40px;
    }
    .dpd__image{
        width: 100%;
        display: grid;
        place-content: center;
    }
    .dpd__img{
        width: 430px;
        height: 279px;
    }
}  
@media (min-width:1024px) and (max-width:1279px) {
    .dpd__img{
        width: 430px;
        height: 269px;
    }
}
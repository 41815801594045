@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,600;1,700;1,800;1,900&display=swap');
.dropArea{
    width: 250px;
    height: 250px;
    background: rgba(217, 217, 217, 0.40);
}
.product__image{
    width: 250px;
    height: 250px;
    object-fit: contain;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.product{
    width: 100%;
    height: fit-content;
    background: #EFEFEF;
}
.product__container{
    display: flex;
    justify-content: end;
    column-gap: 100px;
}
.product__content{
    width: 920px;
    height: 611px;
    background-color: #fff;
}
.product__img{
    width: 370px;
    height: 589px;
    background-color: #fff;
    margin-right: 30px;
    margin-top: 20px;
}
.product__title{
    color: #1071FF;
    font-size: 22px;
    font-weight: 500;
}
.product__para{
    color: #0C0C0C;
    font-size: 14px;
    font-weight: 400;
}
.product__input{
    outline: none;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    padding: 0px 12px;
    height: 48px;
    color: #898989;
    font-size: 14px;
    font-weight: 400;
    display: grid;
}
.product__input > input{
    outline: none;
}
.product__btn{
    width: 374px;
    height: 42px;
    background-color: #1071FF;
    color: #fff;
    border-radius: 30px;
    outline: none;
}
.product__btn > button{
    width: 100%;
    height: 100%;
}
.dropArea__title{
    color: #1071FF;
    font-size: 14px;
    font-weight: 500;
}
.dropArea__para{
    color: #0C0C0C;
    font-size: 12px;
    font-weight: 500;
}
.fa-upload{
    font-size: 32px;
}
.t-shirt__img{
    width: 230px;
    height: 230px;
}
.t-shirt__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fa-circle-xmark{
    font-size: 32px;
    color: red;
}
.fa-circle-check{
    font-size: 32px;
    color: rgb(31, 230, 31);
}
@media (min-width:1831px) and (max-width: 1859px) {
    .product__container{
        column-gap: 80px;
    }
}
@media (min-width: 1800px) and (max-width:1830px) {
    .product__container{
        column-gap: 70px;
    }
}
@media (min-width: 1760px) and (max-width:1799px){
    .product__container{
        column-gap: 50px;
    }
}
@media (min-width: 1730px) and (max-width:1759px){
    .product__container{
        column-gap: 25px;
    }
}
@media (min-width: 1680px) and (max-width:1729px){
    .product__container{
        column-gap: 10px;
    }
}
@media (min-width: 1630px) and (max-width:1679px){
    .product__container{
        column-gap: 10px;
    }
    .product__img{
        margin-right: 0px;
    }
}
@media (min-width: 1580px) and (max-width:1629px){
    .product__container{
        column-gap: 10px;
    }
    .product__img{
        margin-right: 0px;
        width: 350px;
    }
}
@media (min-width: 1534px) and (max-width:1579px){
    .product__container{
        column-gap: 10px;
    }
    .product__img{
        margin-right: 0px;
        width: 330px;
    }
}
@media only screen and (max-width: 1534px) {
    .product__container{
        display: grid;
        place-content: center;
    }
    .product{
        height: fit-content;
    }
    .product__img{
        width: 920px;
        height: fit-content;
        display: flex;
        align-items: center;
        column-gap: 20px;
    }
}
@media only screen and (max-width: 767px) {
    .product__content{
        width: 100%;
        height: fit-content;
    }
    .product__img{
        display: none;
    }
    .product__btn{
        width: 300px;
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .product__img{
        width: 100%;
    }
    .t-shirt__img{
        width: 250px;
    }
}
